import React from 'react'
import { navigate } from '@reach/router'
import { isLoggedIn, isMod } from '../utils/auth'

class PrivateModRoute extends React.Component {
    render() {
        const { component: Component, location, ...rest } = this.props
        if (!isLoggedIn()) {
            navigate(`/app/login`)
            return null
        }
        if (!isMod()) {
            navigate(`/`)
            return null
        }
        return <Component {...rest} />
    }
}

export default PrivateModRoute
