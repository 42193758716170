import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { setUser, isLoggedIn } from '../utils/auth'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify'
import { Link } from 'gatsby'
import logo from './icons/funFactLogo/logo.png'

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email address is required'),
    password: Yup.string()
        .required('Password is required')
});

function Login() {
    const [error, setError] = useState('')

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const login = async ({ email, password }) => {
        try {
            await Auth.signIn(email, password)
            const user = await Auth.currentAuthenticatedUser()
            const userInfo = {
                ...user.attributes,
                username: user.username,
                groups: user.signInUserSession.idToken.payload['cognito:groups']
                    ? user.signInUserSession.idToken.payload['cognito:groups']
                    : [],
            }
            setUser(userInfo)
            navigate('/')
        } catch (err) {
            setError(err)
        }
    }
    if (isLoggedIn()) navigate('/')

    return (
        <div className="min-h-screen flex flex-col py-10 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    src={logo}
                    alt="Fun Fact Checker"
                />
                <h2 className="mt-2 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Log In
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    Or{' '}
                    <Link to="/app/signup" className="font-medium text-blue-600 hover:text-blue-500">sign up to post your own facts</Link>
                </p>
            </div>

            <form onSubmit={handleSubmit(login)}>
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-gray-100 border- py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-5 text-ffpurple-500"
                            >
                                Email address
                            </label>
                            <div className="mt-1 rounded-md shadow-sm">
                                <input
                                    id="email"
                                    placeholder="Email address"
                                    {...register("email")}
                                    className={`${errors.email ? "border-2 border-red-500" : "border border-gray-300 focus:ring-blue focus:border-blue-300"}  appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                                />
                            </div>
                            {errors.email &&
                                <div className="max-w-xl text-xs font-medium text-red-500">
                                    <p>{errors.email?.message}</p>
                                </div>
                            }
                        </div>

                        <div className="mt-6">
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium leading-5 text-ffpurple-500"
                            >
                                Password
                            </label>
                            <div className="mt-1 rounded-md shadow-sm">
                                <input
                                    id="password"
                                    type="password"
                                    placeholder="Password"
                                    {...register("password")}
                                    className={`${errors.password ? "border-2 border-red-500" : "border border-gray-300 focus:ring-blue focus:border-blue-300"}  appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                                />
                            </div>
                            {errors.password &&
                                <div className="max-w-xl text-xs font-medium text-red-500">
                                    <p>{errors.password?.message}</p>
                                </div>
                            }
                        </div>

                        <div className="mt-6 flex items-center justify-between">
                            <div className="text-sm leading-5">
                                {/* TODO: ADD FORGOT YOUR PASSWORD FEATURE */}
                                {/* <a
                                href="#"
                                className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                            >
                                Forgot your password?
                            </a> */}

                            </div>
                        </div>

                        <div className="mt-6">
                            <span className="block w-full rounded-md shadow-sm">
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-ffpurple-500 hover:bg-ffpurple-400 focus:outline-none focus:border-ffpurple-700 focus:ring-ffpurple active:bg-ffpurple-700 transition duration-150 ease-in-out"
                                >
                                    Log in
                                </button>
                            </span>
                        </div>
                        {error &&
                            <div className="max-w-xl text-xs font-medium text-red-500">
                                <p>Whoa! {error.message ? error.message : 'Something went wrong!'}. Please try again later.</p>
                            </div>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Login
