import React, { useState, useEffect } from 'react'
import TagList from '../../shared/TagList'
import FunRating from '../../shared/FunRating'
import { getFact } from '../../../actions/factActions'
import { Link } from 'gatsby'
import { isLoggedIn, getUserName } from '../../../utils/auth'
import { navigate } from 'gatsby'
import _ from 'lodash'

const FactDetails = props => {
    const { factId } = props
    const [fact, setFact] = useState({})
    const [loading, setLoading] = useState(true)

    const canEdit = () => {
        if (!isLoggedIn()) {
            return false
        }

        const userName = getUserName()
        return fact.author === userName;
    }

    useEffect(() => {
        getFact(factId)
            .then(data => {
                if (_.isEmpty(data)) {
                    navigate('/404')
                }
                setFact(data)
            })
            .catch(error => {
                console.log(error)
                // TODO: something with this error
            })
            .finally(() => {
                setLoading(false)
            })
    }, [factId])

    return (
        <>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                    {loading ? (
                        <div className="rounded h-5 w-64 animate-pulse bg-gray-300" />
                    ) : (
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {fact.title}
                        </h3>
                    )}
                    {!loading && (
                        <div className="mt-1 max-w-2xl leading-5 ">
                            <TagList tags={fact.tags} />
                        </div>
                    )}
                </div>
                <div className="px-4 pt-5 border-b border-gray-200 sm:px-6">
                    {loading ? (
                        <div
                            className={`rounded h-2 mb-4 w-full animate-pulse bg-purple-300`}
                        ></div>
                    ) : (
                        <FunRating
                            score={fact.funRating}
                            voteCount={fact.numTotalRatings}
                        />
                    )}
                </div>

                <div className="px-4 py-5 sm:p-0">
                    <dl>
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Description
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {loading ? (
                                    <>
                                        <div className="rounded h-4 w-full mb-1 animate-pulse bg-gray-300"></div>
                                        <div className="rounded h-4 w-full mb-1 animate-pulse bg-gray-300"></div>
                                        <div className="rounded h-4 w-24 animate-pulse bg-gray-300"></div>
                                    </>
                                ) : (
                                    <>{fact.description}</>
                                )}
                            </dd>
                        </div>
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Author
                            </dt>

                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {loading ? (
                                    <div className="rounded h-4 w-32 animate-pulse bg-gray-300" />
                                ) : (
                                    <>{fact.author}</>
                                )}
                            </dd>
                        </div>
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Source
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {loading ? (
                                    <div className="rounded h-4 w-64 animate-pulse bg-gray-300"></div>
                                ) : (
                                    <>{fact.sourceReference}</>
                                )}
                            </dd>
                        </div>
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Proof Link
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {loading ? (
                                    <div className="rounded h-4 w-64 animate-pulse bg-gray-300"></div>
                                ) : (
                                    <>{fact.proofLink}</>
                                )}
                            </dd>
                        </div>
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Verified
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {loading ? (
                                    <div className="rounded h-4 w-20 animate-pulse bg-gray-300"></div>
                                ) : (
                                    <>
                                        {fact.verified
                                            ? 'Verified'
                                            : 'Not Verified'}
                                    </>
                                )}
                            </dd>
                        </div>
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Date Posted
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {loading ? (
                                    <div className="rounded h-4 w-20 animate-pulse bg-gray-300"></div>
                                ) : (
                                    <>{fact.datePosted}</>
                                )}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            {canEdit() && (
                <div className="pt-5">
                    <div className="flex justify-end">
                        <span className="ml-3 inline-flex rounded-md shadow-sm">
                            {loading ? (
                                <span className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-ffpurple-500 transition duration-150 ease-in-out opacity-50 cursor-not-allowed">
                                    Edit
                                </span>
                            ) : (
                                <Link
                                    to={`/app/facts/${factId}/edit`}
                                    className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-ffpurple-500 hover:bg-ffpurple-400 focus:outline-none focus:border-ffpurple-600 focus:ring-ffpurple active:bg-ffpurple-600 transition duration-150 ease-in-out"
                                >
                                    Edit
                                </Link>
                            )}
                        </span>
                    </div>
                </div>
            )}
        </>
    )
}

export default FactDetails
