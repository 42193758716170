import React from 'react'
import ModeratorStats from './ModeratorStats'
import ModeratorCardList from './ModeratorCardList'
import FACTS from '../../mock-data/facts.js'

const ModeratorDashboard = () => (
    <>
        <ModeratorStats totalToDo={FACTS.length} />
        <hr className="mt-4 mb-4" />
        <h3 className="text-lg leading-6 mb-2 font-medium text-gray-900">
            Cards to be verified
        </h3>
        <ModeratorCardList facts={FACTS.default} />
    </>
)

export default ModeratorDashboard
