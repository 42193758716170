import React from 'react'

const ViewCarouselSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            className="w-5 h-5 text-white mb-auto"
            fill="currentColor"
        >
            <path d="M16 16v2H4v-2H0V4h4V2h12v2h4v12h-4zM14 5.5V4H6v12h8V5.5zm2 .5v8h2V6h-2zM4 6H2v8h2V6z" />
        </svg>
    )
}

export default ViewCarouselSvg
