import React from 'react'
// import VerifiedSvg from '../icons/VerifiedSvg'
// import PlausibleSvg from '../icons/PlausibleSvg'
// import FalseSvg from '../icons/FalseSvg'
import TagList from '../shared/TagList'

const ModeratorCard = props => {
    const { fact } = props

    return (
        <li className="col-span-1 bg-gray-50 rounded-lg shadow">
            <div className="w-full flex items-center justify-between pl-6 pr-6 pt-6 pb-2 space-x-6">
                <div className="flex-1">
                    <div className="flex items-center space-x-3">
                        <h3 className="text-gray-900 text-sm leading-5 font-medium truncate">
                            {fact.title}
                        </h3>
                        <TagList tags={fact.tags} />
                    </div>
                    <p className="mt-1 text-gray-500 text-sm leading-5">
                        {fact.description}
                    </p>
                    <div className="mt-2 text-sm leading-5">
                        <span className="mr-2">Proof Link:</span>
                        <a
                            className="text-blue-500 hover:text-blue-800 text-sm leading-5"
                            href={fact.proofLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {fact.proofLink}
                        </a>
                    </div>
                </div>
            </div>
            <div className="-mt-px flex">
                <div className="w-0 flex-1 flex border-r border-gray-200">
                    {/* <a
                        href="#"
                        className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-2 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                    >
                        <FalseSvg />
                        <span className="ml-3">False</span>
                    </a> */}
                </div>
                <div className="-ml-px w-0 flex-1 flex border-r border-gray-200">
                    {/* <a
                        href="#"
                        className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-2 text-sm leading-5 text-gray-700 font-medium border border-transparent hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                    >
                        <PlausibleSvg />
                        <span className="ml-3">Plausible</span>
                    </a> */}
                </div>
                <div className="-ml-px w-0 flex-1 flex">
                    {/* <a
                        href="#"
                        className="relative w-0 flex-1 inline-flex items-center justify-center py-2 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                    >
                        <VerifiedSvg />
                        <span className="ml-3">True</span>
                    </a> */}
                </div>
            </div>
        </li>
    )
}

export default ModeratorCard
