import React, { useEffect, useState } from 'react'
import { getMyFacts } from '../actions/factActions'
import { getCurrentUser } from '../utils/auth'
import FunFact from './fun-facts/FunFact'
import FunFactSkeletonList from './shared/Loaders/FunFactSkeletonList'

const Home = () => {
  const user = getCurrentUser()
  const [facts, setFacts] = useState([])
  const [loadingFacts, setLoadingFacts] = useState(true)
  const [loadingError, setLoadingError] = useState(null)

  useEffect(() => {
    getMyFacts(user.username)
      .then(data => {
        setFacts(data)
      })
      .catch(error => {
        setLoadingError(error)
      })
      .finally(() => {
        setLoadingFacts(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortFacts = (a, b) => {
    if (a.funRating === b.funRating) {
      return b.numTotalRatings - a.numTotalRatings
    }

    return a.funRating < b.funRating ? 1 : -1
  }

  return (
    <div>
      <h1 className="text-lg leading-6 font-medium text-gray-900">
        👋 Hello, {user.username}
      </h1>
      <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-medium text-gray-500">
                Email
              </dt>

              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {user.email}
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-medium text-gray-500">
                Username
              </dt>
              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {user.username}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <hr className='my-5' />
      <h2 className="text-lg leading-6 font-medium text-gray-900">
        My Facts
      </h2>
      <div className='mt-4'>
        {loadingFacts ? (
          <ul className="grid grid-cols-1 gap-6">
            <FunFactSkeletonList showBtns={false} cardCount={3} />
          </ul>
        ) : (
          loadingError ?
            <div>
              Error loading facts. Please try refreshing the page.
            </div>
            :
            <ul className="grid sm:grid-cols-2 grid-cols-1 gap-6">
              {facts &&
                facts.length > 0 &&
                facts
                  .sort(sortFacts)
                  .map(fact => (
                    <FunFact
                      fact={fact}
                      key={fact.id}
                      voteAsFunFn={() => { }}
                      voteAsNotFunFn={() => { }}
                      hasVoted={true}
                    />
                  ))}
            </ul>
        )}
      </div>
      {/* <p>TODO - Add Change Password</p> */}
    </div>
  )
}

export default Home