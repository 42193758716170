import React from 'react'
import ModeratorCard from './ModeratorCard'

const ModeratorCardList = ({ facts }) => {
    return (
        <ul className="grid grid-cols-1 gap-6">
            {facts &&
                facts.length > 0 &&
                facts.map((fact, key) => (
                    <ModeratorCard fact={fact} key={key} />
                ))}
        </ul>
    )
}

export default ModeratorCardList
