import React, { useState, useEffect } from 'react'
import VerifiedSvg from '../../icons/VerifiedSvg'
import FunRating from '../../shared/FunRating'
import RemovableTagList from '../../shared/RemovableTagList'
import { getFact, putFact, createFact, delFact } from '../../../actions/factActions'
import { Link } from 'gatsby'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { navigate } from 'gatsby'
import Modal from '../../shared/Modal'
import { isLoggedIn, getUserName } from '../../../utils/auth'

const validationSchema = Yup.object().shape({
    id: Yup.string(),
    title: Yup.string()
        .required('Title is required'),
    description: Yup.string()
        .required('Description is required'),
    sourceReference: Yup.string(),
    proofLink: Yup.string(),
    tags: Yup.array()
        .max(5, 'Only 5 tags are allowed'),
    factCheckStatus: Yup.boolean(),
    author: Yup.string(),
    authorImg: Yup.string(),
    funRating: Yup.number(),
    numPositiveRatings: Yup.number(),
    datePosted: Yup.string(),
    numTotalRatings: Yup.number(),
});

const MODAL_TEXT = {
    acceptBtn: 'Delete',
    title: 'Whoa! Are you sure?',
    description: 'Just want to confirm that you meant to actually delete your fact.'
}


function AdministrateFact({ newFact, factId }) {
    const [saving, setSaving] = useState(false)
    const [loading, setLoading] = useState(true)
    const [confirmDeleting, setConfirmDeleting] = useState(false)
    const [displayModal, setDisplayModal] = useState(false)
    // TODO: REMOVE DESCRIPTIONS ABOUT HOW IT WORKS
    // TODO: UPDATE FUNRATING WHEN EDIT MODE

    const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id: '',
            title: '',
            description: '',
            sourceReference: '',
            proofLink: '',
            tags: [],
            factCheckStatus: false,
            author: '',
            authorImg: '',
            funRating: 0,
            numPositiveRatings: 0,
            datePosted: '',
            numTotalRatings: 0
        }
    });

    useEffect(() => {
        if (!newFact) {
            setLoading(true)
            getFact(factId)
                .then(data => {
                    if (data === {}) {
                        navigate('/404')
                    }

                    if (canEdit(data)) {
                        reset(data)
                    }
                    else {
                        navigate(`/app/facts/${factId}`)
                    }

                })
                .catch(error => {
                    console.log(error)
                    // TODO: something with this error
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        else {
            setLoading(false)
        }
    }, [newFact, factId, reset])

    const canEdit = (fact) => {
        if (!isLoggedIn()) {
            return false
        }

        const userName = getUserName()
        return fact.author === userName;
    }

    const confirmDeleteFact = () => {
        setConfirmDeleting(true)
        delFact(factId)
            .then(res => {
                if (res) {
                    navigate('/')
                } else {
                    console.log('there was an error')
                    setConfirmDeleting(false)
                }
            })
            .catch(error => {
                console.log(error)
                setConfirmDeleting(false)
            })
    }

    const deleteFact = () => {
        setDisplayModal(true)
    }

    const dismissModal = () => {
        setDisplayModal(false)
    }

    const saveFact = (fact) => {
        setSaving(true)
        if (newFact) {
            createFact(fact)
                .then(res => {
                    if (res && res.successful) {
                        navigate(`/app/facts/${res.values.id}`)
                    } else {
                        console.log('there was an error')
                        setSaving(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setSaving(false)
                })
        } else {
            putFact(fact)
                .then(res => {
                    if (res && res.successful) {
                        navigate(`/app/facts/${factId}`)
                    } else {
                        console.log('there was an error')
                        setSaving(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setSaving(false)
                })
        }
    }

    return (
        <form onSubmit={handleSubmit(saveFact)}>
            {displayModal && <Modal acceptFn={confirmDeleteFact} cancelFn={dismissModal} disabled={confirmDeleting} textOptions={MODAL_TEXT} />}
            <div>
                <div>
                    <div>
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            {newFact ? 'New Fun Fact' : 'Edit Fun Fact'}
                        </h2>
                        {newFact && (
                            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                Let's start with your 'Fun Fact' first! Give
                                your fact a catchy title and an interesting
                                description.
                            </p>
                        )}
                    </div>
                    <div className="mt-6 sm:mt-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="title"
                                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Title
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg flex rounded-md shadow-sm">
                                    {loading ? (
                                        <div className="rounded h-10 w-full animate-pulse bg-gray-300"></div>
                                    ) : (
                                        <input
                                            id="title"
                                            {...register("title")}
                                            className={`${errors.title ? "border-2 border-red-500" : "border border-gray-300 focus:ring-blue focus:border-blue-300"} flex-1 form-input border block w-full min-w-0 rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                                        />)
                                    }
                                </div>
                                {errors.title &&
                                    <div className="max-w-xl text-xs font-medium text-red-500">
                                        <p>{errors.title?.message}</p>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="mt-6 mb-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="description"
                                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Description
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg flex rounded-md shadow-sm">
                                    {loading ? (
                                        <div className="rounded h-20 w-full animate-pulse bg-gray-300"></div>
                                    ) : (
                                        <textarea
                                            id="description"
                                            rows="3"
                                            {...register("description")}
                                            className={`${errors.description ? "border-2 border-red-500" : "border border-gray-300 focus:ring-blue focus:border-blue-300"} form-textarea block w-full border transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                                        ></textarea>
                                    )}
                                </div>
                                {errors.description &&
                                    <div className="max-w-xl text-xs font-medium text-red-500">
                                        <p>{errors.description?.message}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="tags"
                                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Tags
                            </label>

                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg flex rounded-md">
                                    <RemovableTagList
                                        tags={watch('tags')}
                                        name={'tags'}
                                        setValue={setValue}
                                        loading={loading}
                                    />
                                </div>
                                {errors.tags &&
                                    <div className="max-w-xl text-xs font-medium text-red-500">
                                        <p>{errors.tags?.message}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Additional Information
                        </h3>
                        {newFact && (
                            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                Now let's get 'Meta' and add some additional
                                information. This stuff isn't required, but our
                                algorithms favor it!
                            </p>
                        )}
                    </div>
                    <div className="mt-6 sm:mt-5">
                        <div className="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="sourceReference"
                                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Source Reference
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg flex rounded-md shadow-sm">
                                    {loading ? (
                                        <div className="rounded h-20 w-full animate-pulse bg-gray-300"></div>
                                    ) : (
                                        <input
                                            id="sourceReference"
                                            {...register("sourceReference")}
                                            className={`${errors.sourceReference ? "border-2 border-red-500" : "border border-gray-300 focus:ring-blue focus:border-blue-300"} flex-1 form-input border block w-full min-w-0 rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                                        />
                                    )}
                                </div>
                                {errors.sourceReference &&
                                    <div className="max-w-xl text-xs font-medium text-red-500">
                                        <p>{errors.sourceReference?.message}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="proofLink"
                                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                            >
                                <div className="flex flex-wrap">
                                    <VerifiedSvg />
                                    <div className="ml-2">Proof Link</div>
                                </div>
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg flex rounded-md shadow-sm">
                                    {loading ? (
                                        <div className="rounded h-20 w-full animate-pulse bg-gray-300"></div>
                                    ) : (
                                        <input
                                            id="proofLink"
                                            {...register("proofLink")}
                                            className={`${errors.proofLink ? "border-2 border-red-500" : "border border-gray-300 focus:ring-blue focus:border-blue-300"} flex-1 form-input border block w-full min-w-0 rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                                        />
                                    )}
                                </div>
                                {errors.proofLink &&
                                    <div className="max-w-xl text-xs font-medium text-red-500">
                                        <p>{errors.proofLink?.message}</p>
                                    </div>
                                }
                                {newFact && (
                                    <p className="mt-2 text-sm text-gray-500">
                                        Not required unless you would like a
                                        Verified badge. Our expert admins will
                                        read your proof link and if they
                                        determine that it is true, then they
                                        will grant you the verified badge!
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="funRating"
                                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Fun Rating
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                {newFact ? (
                                    <FunRating score={100} voteCount={1} />
                                ) : (
                                    <FunRating
                                        score={watch('funRating')}
                                        voteCount={watch('numTotalRatings')}
                                        loading={loading}
                                    />
                                )}

                                {newFact && (
                                    <p className="mt-2 text-sm text-gray-500">
                                        Your Fun Rating will start at 100%
                                        because YOU think your fact is fun. But
                                        will everyone else? The more people that
                                        vote for it, then the more fun you know
                                        it is!
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-5">
                <div className="flex justify-end">
                    {!newFact && (<span className="inline-flex rounded-md shadow-sm mr-auto">
                        <button
                            type="button"
                            className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-ffpurple active:bg-red-700 transition duration-150 ease-in-out"
                            onClick={deleteFact}
                        >
                            Delete
                        </button>
                    </span>)}
                    <span className="inline-flex rounded-md shadow-sm">
                        <Link
                            to={`/app/facts/${factId}`}
                            className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                        >
                            Cancel
                        </Link>
                    </span>
                    <span className="ml-3 inline-flex rounded-md shadow-sm">
                        {saving && (
                            <button
                                disabled
                                className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-ffpurple-500 hover:bg-ffpurple-400 focus:outline-none focus:border-ffpurple-700 focus:ring-ffpurple active:bg-ffpurple-700 transition duration-150 ease-in-out opacity-50 cursor-not-allowed"
                            >
                                {newFact ? 'Creating' : 'Saving'}
                            </button>
                        )}
                        {!saving && (
                            <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-ffpurple-500 hover:bg-ffpurple-400 focus:outline-none focus:border-ffpurple-700 focus:ring-ffpurple active:bg-ffpurple-700 transition duration-150 ease-in-out"
                            >
                                {newFact ? 'Create' : 'Save'}
                            </button>
                        )}
                    </span>
                </div>
            </div>
        </form>
    )
}

export default AdministrateFact
