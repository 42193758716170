import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const NotFound = () => (
  <Layout>
    <div className="bg-white min-h-full mt-16 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-ffpurple-500 sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Oh no! This is not fun</h1>
              <p className="mt-2 text-base text-gray-500">Something went wrong... Check the address bar and please try again later</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-ffpurple-500 hover:bg-ffpurple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ffpurple-500"
                to={`/`}
              >
                Go back home
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  </Layout>
)

export default NotFound
