export const ADJECTIVES = [
    "abdominal",
    "abhorrent",
    "abiding",
    "abject",
    "able",
    "abnormal",
    "abounding",
    "abrasive",
    "abrupt",
    "absent",
    "absentminded",
    "absolute",
    "absorbed",
    "absorbing",
    "abstracted",
    "absurd",
    "abundant",
    "abusive",
    "abysmal",
    "academic",
    "acceptable",
    "accepting",
    "accessible",
    "accidental",
    "acclaimed",
    "accommodating",
    "accompanying",
    "accountable",
    "accurate",
    "accusative",
    "accused",
    "accusing",
    "acerbic",
    "achievable",
    "aching",
    "acid",
    "acidic",
    "acknowledged",
    "acoustic",
    "acrid",
    "acrimonious",
    "acrobatic",
    "actionable",
    "active",
    "actual",
    "adhoc",
    "adamant",
    "adaptable",
    "adaptive",
    "addicted",
    "addictive",
    "additional",
    "adept",
    "adequate",
    "adhesive",
    "adjacent",
    "adjoining",
    "adjustable",
    "administrative",
    "admirable",
    "admired",
    "admiring",
    "adopted",
    "adoptive",
    "adorable",
    "adored",
    "adoring",
    "adrenalized",
    "adroit",
    "adult",
    "advanced",
    "advantageous",
    "adventurous",
    "adversarial",
    "advisable",
    "aerial",
    "affable",
    "affected",
    "affectionate",
    "affirmative",
    "affordable",
    "afraid",
    "afternoon",
    "ageless",
    "aggravated",
    "aggravating",
    "aggressive",
    "agitated",
    "agonizing",
    "agrarian",
    "agreeable",
    "aimless",
    "airline",
    "airsick",
    "ajar",
    "alarmed",
    "alarming",
    "alert",
    "algebraic",
    "alien",
    "alienated",
    "alike",
    "alive",
    "all",
    "alleged",
    "allowable",
    "alluring",
    "allusive",
    "alone",
    "aloof",
    "alterable",
    "alternating",
    "alternative",
    "amazed",
    "amazing",
    "ambiguous",
    "ambitious",
    "ambulant",
    "ambulatory",
    "amiable",
    "amicable",
    "amphibian",
    "amused",
    "amusing",
    "an",
    "ancient",
    "anecdotal",
    "anemic",
    "angelic",
    "angered",
    "angry",
    "angular",
    "animal",
    "animated",
    "annoyed",
    "annoying",
    "annual",
    "anonymous",
    "another",
    "antagonistic",
    "anticipated",
    "anticlimactic",
    "anticorrosive",
    "antiquated",
    "antiseptic",
    "antisocial",
    "antsy",
    "anxious",
    "any",
    "apathetic",
    "apologetic",
    "apologizing",
    "appalling",
    "appealing",
    "appetizing",
    "applauding",
    "applicable",
    "applicative",
    "appreciative",
    "apprehensive",
    "approachable",
    "approaching",
    "appropriate",
    "approving",
    "approximate",
    "aquatic",
    "architectural",
    "ardent",
    "arduous",
    "arguable",
    "argumentative",
    "arid",
    "aristocratic",
    "aromatic",
    "arresting",
    "arrogant",
    "artful",
    "artificial",
    "artistic",
    "artless",
    "ashamed",
    "aspiring",
    "assertive",
    "assignable",
    "assorted",
    "assumable",
    "assured",
    "assuring",
    "astonished",
    "astonishing",
    "astounded",
    "astounding",
    "astringent",
    "astronomical",
    "astute",
    "asymmetrical",
    "athletic",
    "atomic",
    "atrocious",
    "attachable",
    "attainable",
    "attentive",
    "attractive",
    "attributable",
    "atypical",
    "audacious",
    "auspicious",
    "authentic",
    "authoritarian",
    "authoritative",
    "autobiographic",
    "autographed",
    "automatic",
    "autonomous",
    "available",
    "avenging",
    "average",
    "avian",
    "avid",
    "avoidable",
    "awake",
    "awakening",
    "aware",
    "away",
    "awesome",
    "awful",
    "awkward",
    "axiomatic",
    "babbling",
    "baby",
    "background",
    "backhanded",
    "bacterial",
    "bad",
    "baffled",
    "baffling",
    "bald",
    "balding",
    "balmy",
    "bandaged",
    "banging",
    "bankable",
    "banned",
    "bantering",
    "barbaric",
    "barbarous",
    "barbequed",
    "barefooted",
    "barking",
    "barren",
    "bashful",
    "basic",
    "battered",
    "batty",
    "bawling",
    "beady",
    "beaming",
    "bearable",
    "beautiful",
    "beckoning",
    "bedazzled",
    "bedazzling",
    "beefy",
    "beeping",
    "befitting",
    "befuddled",
    "beginning",
    "belching",
    "believable",
    "bellicose",
    "belligerent",
    "bellowing",
    "bendable",
    "beneficial",
    "benevolent",
    "benign",
    "bent",
    "berserk",
    "best",
    "betrayed",
    "better",
    "betteroff",
    "bewildered",
    "bewildering",
    "bewitched",
    "bewitching",
    "biased",
    "biblical",
    "big",
    "bigger",
    "biggest",
    "bighearted",
    "bigoted",
    "bilingual",
    "billable",
    "billowy",
    "binary",
    "binding",
    "bioactive",
    "biodegradable",
    "biographical",
    "biting",
    "bitter",
    "bizarre",
    "black",
    "blamable",
    "blameless",
    "bland",
    "blank",
    "blaring",
    "blasphemous",
    "blatant",
    "blazing",
    "bleached",
    "bleak",
    "bleary",
    "blessed",
    "blind",
    "blindfolded",
    "blinding",
    "blissful",
    "blistering",
    "bloated",
    "blonde",
    "bloodied",
    "bloodthirsty",
    "bloody",
    "blooming",
    "blossoming",
    "blue",
    "blundering",
    "blunt",
    "blurred",
    "blurry",
    "blushing",
    "boastful",
    "bodacious",
    "bohemian",
    "boiling",
    "boisterous",
    "bold",
    "bookish",
    "booming",
    "boorish",
    "bordering",
    "bored",
    "boring",
    "born",
    "bossy",
    "both",
    "bothered",
    "bouncing",
    "bouncy",
    "boundless",
    "bountiful",
    "boyish",
    "braided",
    "brainless",
    "brainy",
    "brash",
    "brassy",
    "brave",
    "brawny",
    "brazen",
    "breakable",
    "breathable",
    "breathless",
    "breathtaking",
    "breezy",
    "bribable",
    "brick",
    "brief",
    "bright",
    "brilliant",
    "briny",
    "brisk",
    "bristly",
    "broad",
    "broken",
    "bronchial",
    "bronze",
    "bronzed",
    "brooding",
    "brown",
    "bruised",
    "brunette",
    "brutal",
    "brutish",
    "bubbly",
    "budget",
    "bulky",
    "bumpy",
    "bungling",
    "buoyant",
    "bureaucratic",
    "burly",
    "burnable",
    "burning",
    "bushy",
    "busiest",
    "business",
    "bustling",
    "busy",
    "buzzing",
    "cackling",
    "caged",
    "cagey",
    "calculable",
    "calculated",
    "calculating",
    "callous",
    "calm",
    "calming",
    "camouflaged",
    "cancelled",
    "cancerous",
    "candid",
    "cantankerous",
    "capable",
    "capricious",
    "captivated",
    "captivating",
    "captive",
    "carefree",
    "careful",
    "careless",
    "caring",
    "carnivorous",
    "carpeted",
    "carsick",
    "casual",
    "catastrophic",
    "catatonic",
    "catchable",
    "caustic",
    "cautious",
    "cavalier",
    "cavernous",
    "ceaseless",
    "celebrated",
    "celestial",
    "centered",
    "central",
    "cerebral",
    "ceremonial",
    "certain",
    "certifiable",
    "certified",
    "challenged",
    "challenging",
    "chance",
    "changeable",
    "changing",
    "chanting",
    "charging",
    "charismatic",
    "charitable",
    "charmed",
    "charming",
    "chattering",
    "chatting",
    "chatty",
    "chauvinistic",
    "cheap",
    "cheapest",
    "cheeky",
    "cheerful",
    "cheering",
    "cheerless",
    "cheery",
    "chemical",
    "chewable",
    "chewy",
    "chic",
    "chicken",
    "chief",
    "childish",
    "childlike",
    "chilling",
    "chilly",
    "chivalrous",
    "choice",
    "choking",
    "choppy",
    "chronological",
    "chubby",
    "chuckling",
    "chunky",
    "cinematic",
    "circling",
    "circular",
    "circumstantial",
    "civil",
    "civilian",
    "civilized",
    "clammy",
    "clamoring",
    "clandestine",
    "clanging",
    "clapping",
    "clashing",
    "classic",
    "classical",
    "classifiable",
    "classified",
    "classy",
    "clean",
    "cleanable",
    "clear",
    "cleared",
    "clearheaded",
    "clever",
    "climatic",
    "climbable",
    "clinging",
    "clingy",
    "clinical",
    "cliquish",
    "clogged",
    "cloistered",
    "close",
    "closeable",
    "closed",
    "cloudless",
    "cloudy",
    "clownish",
    "clueless",
    "clumsy",
    "cluttered",
    "coachable",
    "coarse",
    "cockamamie",
    "cocky",
    "codified",
    "coercive",
    "cognitive",
    "coherent",
    "cohesive",
    "coincidental",
    "cold",
    "coldhearted",
    "collaborative",
    "collapsed",
    "collapsing",
    "collectable",
    "collegial",
    "colloquial",
    "colonial",
    "colorful",
    "colorless",
    "colossal",
    "combative",
    "combined",
    "comfortable",
    "comforted",
    "comforting",
    "comical",
    "commanding",
    "commemorative",
    "commendable",
    "commercial",
    "committed",
    "common",
    "communal",
    "communicable",
    "communicative",
    "communist",
    "compact",
    "comparable",
    "comparative",
    "compassionate",
    "compelling",
    "competent",
    "competitive",
    "complacent",
    "complaining",
    "complete",
    "completed",
    "complex",
    "compliant",
    "complicated",
    "complimentary",
    "compound",
    "comprehensive",
    "compulsive",
    "compulsory",
    "computer",
    "computerized",
    "concealable",
    "concealed",
    "conceited",
    "conceivable",
    "concerned",
    "concerning",
    "concerted",
    "concise",
    "concurrent",
    "condemned",
    "condensed",
    "condescending",
    "conditional",
    "confident",
    "confidential",
    "confirmable",
    "confirmed",
    "conflicted",
    "conflicting",
    "conformable",
    "confounded",
    "confused",
    "confusing",
    "congenial",
    "congested",
    "congressional",
    "congruent",
    "congruous",
    "connectable",
    "connected",
    "connecting",
    "connective",
    "conscientious",
    "conscious",
    "consecutive",
    "consensual",
    "consenting",
    "conservative",
    "considerable",
    "considerate",
    "consistent",
    "consoling",
    "conspicuous",
    "conspiratorial",
    "constant",
    "constitutional",
    "constrictive",
    "constructive",
    "consumable",
    "consummate",
    "contagious",
    "containable",
    "contemplative",
    "contemporary",
    "contemptible",
    "contemptuous",
    "content",
    "contented",
    "contentious",
    "contextual",
    "continual",
    "continuing",
    "continuous",
    "contoured",
    "contractual",
    "contradicting",
    "contradictory",
    "contrarian",
    "contrary",
    "contributive",
    "contrite",
    "controllable",
    "controlling",
    "controversial",
    "convenient",
    "conventional",
    "conversational",
    "convinced",
    "convincing",
    "convoluted",
    "convulsive",
    "cooing",
    "cooked",
    "cool",
    "coolest",
    "cooperative",
    "coordinated",
    "copious",
    "coquettish",
    "cordial",
    "corner",
    "cornered",
    "corny",
    "corporate",
    "corpulent",
    "correct",
    "correctable",
    "corrective",
    "corresponding",
    "corrosive",
    "corrupt",
    "corrupting",
    "corruptive",
    "cosmetic",
    "cosmic",
    "costly",
    "cottony",
    "coughing",
    "courageous",
    "courteous",
    "covert",
    "coveted",
    "cowardly",
    "cowering",
    "coy",
    "cozy",
    "crabby",
    "cracked",
    "crackling",
    "crafty",
    "craggy",
    "crammed",
    "cramped",
    "cranky",
    "crashing",
    "crass",
    "craven",
    "crawling",
    "crazy",
    "creaking",
    "creaky",
    "creamy",
    "creative",
    "credible",
    "creeping",
    "creepy",
    "crestfallen",
    "criminal",
    "crippled",
    "crippling",
    "crisp",
    "crispy",
    "critical",
    "crooked",
    "cropped",
    "cross",
    "crossed",
    "crotchety",
    "crowded",
    "crucial",
    "crude",
    "cruel",
    "crumbling",
    "crumbly",
    "crumply",
    "crunchable",
    "crunching",
    "crunchy",
    "crushable",
    "crushed",
    "crusty",
    "crying",
    "cryptic",
    "crystalline",
    "crystallized",
    "cuddly",
    "culpable",
    "cultural",
    "cultured",
    "cumbersome",
    "cumulative",
    "cunning",
    "curable",
    "curative",
    "curious",
    "curly",
    "current",
    "cursed",
    "curt",
    "curved",
    "curvy",
    "customary",
    "cut",
    "cute",
    "cutting",
    "cylindrical",
    "cynical",
    "daffy",
    "daft",
    "daily",
    "dainty",
    "damaged",
    "damaging",
    "damp",
    "danceable",
    "dandy",
    "dangerous",
    "dapper",
    "daring",
    "dark",
    "darkened",
    "dashing",
    "daughterly",
    "daunting",
    "dawdling",
    "day",
    "dazed",
    "dazzling",
    "dead",
    "deadly",
    "deadpan",
    "deaf",
    "deafening",
    "dear",
    "debatable",
    "debonair",
    "decadent",
    "decayed",
    "decaying",
    "deceitful",
    "deceivable",
    "deceiving",
    "decent",
    "decentralized",
    "deceptive",
    "decimated",
    "decipherable",
    "decisive",
    "declining",
    "decorative",
    "decorous",
    "decreasing",
    "decrepit",
    "dedicated",
    "deep",
    "deepening",
    "deeply",
    "defeated",
    "defective",
    "defendable",
    "defenseless",
    "defensible",
    "defensive",
    "defiant",
    "deficient",
    "definable",
    "definitive",
    "deformed",
    "degenerative",
    "degraded",
    "dehydrated",
    "dejected",
    "delectable",
    "deliberate",
    "deliberative",
    "delicate",
    "delicious",
    "delighted",
    "delightful",
    "delinquent",
    "delirious",
    "deliverable",
    "deluded",
    "demanding",
    "demented",
    "democratic",
    "demonic",
    "demonstrative",
    "demure",
    "deniable",
    "dense",
    "dependable",
    "dependent",
    "deplorable",
    "deploring",
    "depraved",
    "depressed",
    "depressing",
    "depressive",
    "deprived",
    "deranged",
    "derivative",
    "derogative",
    "derogatory",
    "descriptive",
    "deserted",
    "designer",
    "desirable",
    "desirous",
    "desolate",
    "despairing",
    "desperate",
    "despicable",
    "despised",
    "despondent",
    "destroyed",
    "destructive",
    "detachable",
    "detached",
    "detailed",
    "detectable",
    "determined",
    "detestable",
    "detrimental",
    "devastated",
    "devastating",
    "devious",
    "devoted",
    "devout",
    "dexterous",
    "diabolical",
    "diagonal",
    "didactic",
    "different",
    "difficult",
    "diffuse",
    "digestive",
    "digital",
    "dignified",
    "digressive",
    "dilapidated",
    "diligent",
    "dim",
    "diminishing",
    "diminutive",
    "dingy",
    "diplomatic",
    "dire",
    "direct",
    "direful",
    "dirty",
    "disabled",
    "disadvantaged",
    "disadvantageous",
    "disaffected",
    "disagreeable",
    "disappearing",
    "disappointed",
    "disappointing",
    "disapproving",
    "disarming",
    "disastrous",
    "discarded",
    "discernable",
    "disciplined",
    "disconnected",
    "discontented",
    "discordant",
    "discouraged",
    "discouraging",
    "discourteous",
    "discredited",
    "discreet",
    "discriminating",
    "discriminatory",
    "discussable",
    "disdainful",
    "diseased",
    "disenchanted",
    "disgraceful",
    "disgruntled",
    "disgusted",
    "disgusting",
    "disheartened",
    "disheartening",
    "dishonest",
    "dishonorable",
    "disillusioned",
    "disinclined",
    "disingenuous",
    "disinterested",
    "disjointed",
    "dislikeable",
    "disliked",
    "disloyal",
    "dismal",
    "dismissive",
    "disobedient",
    "disorderly",
    "disorganized",
    "disparaging",
    "disparate",
    "dispassionate",
    "dispensable",
    "displaced",
    "displeased",
    "displeasing",
    "disposable",
    "disproportionate",
    "disproved",
    "disputable",
    "disputatious",
    "disputed",
    "disreputable",
    "disrespectful",
    "disruptive",
    "dissatisfied",
    "dissimilar",
    "dissolvable",
    "dissolving",
    "dissonant",
    "dissuasive",
    "distant",
    "distasteful",
    "distinct",
    "distinctive",
    "distinguished",
    "distracted",
    "distracting",
    "distraught",
    "distressed",
    "distressing",
    "distrustful",
    "disturbed",
    "disturbing",
    "divergent",
    "diverging",
    "diverse",
    "diversified",
    "divided",
    "divine",
    "divisive",
    "dizzy",
    "dizzying",
    "doable",
    "documentary",
    "dogged",
    "doggish",
    "dogmatic",
    "doleful",
    "dollish",
    "domed",
    "domestic",
    "dominant",
    "domineering",
    "dorsal",
    "doting",
    "double",
    "doubtful",
    "doubting",
    "dovish",
    "dowdy",
    "down",
    "downhearted",
    "downloadable",
    "downtown",
    "downward",
    "dozing",
    "drab",
    "drained",
    "dramatic",
    "drastic",
    "dreaded",
    "dreadful",
    "dreaming",
    "dreamy",
    "dreary",
    "drenched",
    "dress",
    "dressy",
    "dried",
    "dripping",
    "drivable",
    "driven",
    "droll",
    "drooping",
    "droopy",
    "drowsy",
    "drunk",
    "dry",
    "dual",
    "dubious",
    "due",
    "dulcet",
    "dull",
    "duplicitous",
    "durable",
    "dusty",
    "dutiful",
    "dwarfish",
    "dwindling",
    "dynamic",
    "dysfunctional",
    "each",
    "eager",
    "early",
    "earnest",
    "earthshaking",
    "earthy",
    "east",
    "eastern",
    "easy",
    "eatable",
    "eccentric",
    "echoing",
    "ecological",
    "economic",
    "economical",
    "economy",
    "ecstatic",
    "edgy",
    "editable",
    "educated",
    "educational",
    "eerie",
    "effective",
    "effervescent",
    "efficacious",
    "efficient",
    "effortless",
    "effusive",
    "egalitarian",
    "egocentric",
    "egomaniacal",
    "egotistical",
    "eight",
    "eighth",
    "either",
    "elaborate",
    "elastic",
    "elated",
    "elderly",
    "electric",
    "electrical",
    "electrifying",
    "electronic",
    "elegant",
    "elementary",
    "elevated",
    "elfish",
    "eligible",
    "elite",
    "eloquent",
    "elusive",
    "emaciated",
    "embarrassed",
    "embarrassing",
    "embattled",
    "embittered",
    "emblematic",
    "emboldened",
    "embroiled",
    "emergency",
    "eminent",
    "emotional",
    "emotionless",
    "empirical",
    "empty",
    "enamored",
    "enchanted",
    "enchanting",
    "encouraged",
    "encouraging",
    "encrusted",
    "endangered",
    "endearing",
    "endemic",
    "endless",
    "endurable",
    "enduring",
    "energetic",
    "energizing",
    "enforceable",
    "engaging",
    "engrossing",
    "enhanced",
    "enigmatic",
    "enjoyable",
    "enlarged",
    "enlightened",
    "enormous",
    "enough",
    "enraged",
    "ensuing",
    "enterprising",
    "entertained",
    "entertaining",
    "enthralled",
    "enthused",
    "enthusiastic",
    "enticing",
    "entire",
    "entranced",
    "entrepreneurial",
    "enumerable",
    "enviable",
    "envious",
    "environmental",
    "episodic",
    "equable",
    "equal",
    "equidistant",
    "equitable",
    "equivalent",
    "erasable",
    "erect",
    "eroding",
    "errant",
    "erratic",
    "erroneous",
    "eruptive",
    "escalating",
    "esoteric",
    "essential",
    "established",
    "estimated",
    "estranged",
    "eternal",
    "ethereal",
    "ethical",
    "ethnic",
    "euphemistic",
    "euphoric",
    "evasive",
    "even",
    "evenhanded",
    "evening",
    "eventful",
    "eventual",
    "everlasting",
    "every",
    "evil",
    "evocative",
    "exacerbating",
    "exact",
    "exacting",
    "exaggerated",
    "exalted",
    "exasperated",
    "exasperating",
    "excellent",
    "exceptional",
    "excessive",
    "exchangeable",
    "excitable",
    "excited",
    "exciting",
    "exclusive",
    "excruciating",
    "excusable",
    "executable",
    "exemplary",
    "exhausted",
    "exhausting",
    "exhaustive",
    "exhilarated",
    "exhilarating",
    "existing",
    "exotic",
    "expandable",
    "expanded",
    "expanding",
    "expansive",
    "expectant",
    "expected",
    "expedient",
    "expeditious",
    "expendable",
    "expensive",
    "experimental",
    "expert",
    "expired",
    "expiring",
    "explainable",
    "explicit",
    "exploding",
    "exploitative",
    "exploited",
    "explosive",
    "exponential",
    "exposed",
    "express",
    "expressionistic",
    "expressionless",
    "expressive",
    "exquisite",
    "extemporaneous",
    "extendable",
    "extended",
    "extension",
    "extensive",
    "exterior",
    "external",
    "extra",
    "extraneous",
    "extraordinary",
    "extravagant",
    "extreme",
    "exuberant",
    "fabled",
    "fabulous",
    "facetious",
    "facial",
    "factitious",
    "factual",
    "faded",
    "fading",
    "failed",
    "faint",
    "fainthearted",
    "fair",
    "faithful",
    "faithless",
    "fallacious",
    "false",
    "falsified",
    "faltering",
    "familiar",
    "famished",
    "famous",
    "fanatical",
    "fanciful",
    "fancy",
    "fantastic",
    "far",
    "faraway",
    "farcical",
    "farsighted",
    "fascinated",
    "fascinating",
    "fascistic",
    "fashionable",
    "fast",
    "fastest",
    "fastidious",
    "fat",
    "fatal",
    "fateful",
    "fatherly",
    "fathomable",
    "fathomless",
    "fatigued",
    "faulty",
    "favorable",
    "favorite",
    "fawning",
    "feared",
    "fearful",
    "fearless",
    "fearsome",
    "feathered",
    "feathery",
    "feckless",
    "federal",
    "feeble",
    "feebleminded",
    "feeling",
    "feigned",
    "felonious",
    "female",
    "feminine",
    "fermented",
    "ferocious",
    "fertile",
    "fervent",
    "fervid",
    "festive",
    "fetching",
    "fetid",
    "feudal",
    "feverish",
    "few,",
    "fewer",
    "fictional",
    "fictitious",
    "fidgeting",
    "fidgety",
    "fiendish",
    "fierce",
    "fiery",
    "fifth",
    "filmy",
    "filtered",
    "filthy",
    "final",
    "financial",
    "fine",
    "finicky",
    "finite",
    "fireproof",
    "firm",
    "first",
    "fiscal",
    "fishy",
    "fit",
    "fitted",
    "fitting",
    "five",
    "fixable",
    "fixed",
    "flabby",
    "flagrant",
    "flaky",
    "flamboyant",
    "flaming",
    "flammable",
    "flashy",
    "flat",
    "flattened",
    "flattered",
    "flattering",
    "flavored",
    "flavorful",
    "flavorless",
    "flawed",
    "flawless",
    "fleeting",
    "flexible",
    "flickering",
    "flimsy",
    "flippant",
    "flirtatious",
    "floating",
    "flooded",
    "floppy",
    "floral",
    "flowering",
    "flowery",
    "fluent",
    "fluffy",
    "flushed",
    "fluttering",
    "flying",
    "foamy",
    "focused",
    "foggy",
    "folded",
    "following",
    "fond",
    "foolhardy",
    "foolish",
    "forbidding",
    "forceful",
    "foreboding",
    "foregoing",
    "foreign",
    "forensic",
    "foreseeable",
    "forged",
    "forgetful",
    "forgettable",
    "forgivable",
    "forgiving",
    "forgotten",
    "forked",
    "formal",
    "formative",
    "former",
    "formidable",
    "formless",
    "formulaic",
    "forthright",
    "fortuitous",
    "fortunate",
    "forward",
    "foul",
    "four",
    "fourth",
    "foxy",
    "fractional",
    "fractious",
    "fragile",
    "fragmented",
    "fragrant",
    "frail",
    "frank",
    "frantic",
    "fraternal",
    "fraudulent",
    "frayed",
    "freakish",
    "freaky",
    "freckled",
    "free",
    "freezing",
    "frequent",
    "fresh",
    "fretful",
    "fried",
    "friendly",
    "frightened",
    "frightening",
    "frightful",
    "frigid",
    "frilly",
    "frisky",
    "frivolous",
    "front",
    "frosty",
    "frothy",
    "frowning",
    "frozen",
    "frugal",
    "fruitful",
    "fruitless",
    "fruity",
    "frumpy",
    "frustrated",
    "frustrating",
    "fulfilled",
    "fulfilling",
    "full",
    "fumbling",
    "fuming",
    "fun",
    "functional",
    "fundamental",
    "funniest",
    "funny",
    "furious",
    "furry",
    "furthest",
    "furtive",
    "fussy",
    "futile",
    "future",
    "futuristic",
    "fuzzy",
    "gabby",
    "gainful",
    "gallant",
    "galling",
    "game",
    "gangly",
    "gaping",
    "garbled",
    "gargantuan",
    "garish",
    "garrulous",
    "gaseous",
    "gasping",
    "gaudy",
    "gaunt",
    "gauzy",
    "gawky",
    "general",
    "generative",
    "generic",
    "generous",
    "genial",
    "gentle",
    "genuine",
    "geographic",
    "geologic",
    "geometric",
    "geriatric",
    "ghastly",
    "ghostly",
    "ghoulish",
    "giant",
    "giddy",
    "gifted",
    "gigantic",
    "giggling",
    "gilded",
    "giving",
    "glad",
    "glamorous",
    "glaring",
    "glass",
    "glassy",
    "gleaming",
    "glib",
    "glistening",
    "glittering",
    "global",
    "globular",
    "gloomy",
    "glorious",
    "glossy",
    "glowing",
    "gluey",
    "glum",
    "gluttonous",
    "gnarly",
    "gold",
    "golden",
    "good",
    "gooey",
    "goofy",
    "gorgeous",
    "graceful",
    "gracious",
    "gradual",
    "grainy",
    "grand",
    "grandiose",
    "graphic",
    "grateful",
    "gratified",
    "gratifying",
    "grating",
    "gratis",
    "gratuitous",
    "grave",
    "gray",
    "greasy",
    "great",
    "greatest",
    "greedy",
    "green",
    "gregarious",
    "grey",
    "grieving",
    "grim",
    "grimacing",
    "grimy",
    "grinding",
    "grinning",
    "gripping",
    "gritty",
    "grizzled",
    "groaning",
    "groggy",
    "groomed",
    "groovy",
    "gross",
    "grotesque",
    "grouchy",
    "growling",
    "grubby",
    "grueling",
    "gruesome",
    "gruff",
    "grumbling",
    "grumpy",
    "guaranteed",
    "guarded",
    "guiltless",
    "guilty",
    "gullible",
    "gurgling",
    "gushing",
    "gushy",
    "gusty",
    "gutsy",
    "habitable",
    "habitual",
    "haggard",
    "hairless",
    "hairy",
    "half",
    "halfhearted",
    "hallowed",
    "halting",
    "handsome",
    "handy",
    "hanging",
    "haphazard",
    "hapless",
    "happy",
    "hard",
    "hardworking",
    "hardy",
    "harebrained",
    "harmful",
    "harmless",
    "harmonic",
    "harmonious",
    "harried",
    "harsh",
    "hasty",
    "hated",
    "hateful",
    "haughty",
    "haunting",
    "hawkish",
    "hazardous",
    "hazy",
    "head",
    "heady",
    "healthy",
    "heartbreaking",
    "heartbroken",
    "heartless",
    "heartrending",
    "hearty",
    "heated",
    "heavenly",
    "heavy",
    "hectic",
    "hefty",
    "heinous",
    "helpful",
    "helpless",
    "her",
    "heroic",
    "hesitant",
    "hideous",
    "high",
    "highest",
    "highfalutin",
    "hilarious",
    "his",
    "hissing",
    "historical",
    "hoarse",
    "hoggish",
    "holiday",
    "holistic",
    "hollow",
    "home",
    "homeless",
    "homely",
    "homeopathic",
    "homey",
    "homogeneous",
    "honest",
    "honking",
    "honorable",
    "hopeful",
    "hopeless",
    "horizontal",
    "hormonal",
    "horned",
    "horrendous",
    "horrible",
    "horrid",
    "horrific",
    "horrified",
    "horrifying",
    "hospitable",
    "hostile",
    "hot",
    "hotpink",
    "hotheaded",
    "house",
    "howling",
    "huffy",
    "huge",
    "huggable",
    "hulking",
    "human",
    "humanitarian",
    "humanlike",
    "humble",
    "humdrum",
    "humid",
    "humiliated",
    "humiliating",
    "humming",
    "humongous",
    "humorless",
    "humorous",
    "hungry",
    "hurried",
    "hurt",
    "hurtful",
    "hushed",
    "husky",
    "hydraulic",
    "hydrothermal",
    "hygienic",
    "hyperbolic",
    "hypercritical",
    "hyperirritable",
    "hypersensitive",
    "hypertensive",
    "hypnotic",
    "hypnotizable",
    "hypothetical",
    "hysterical",
    "icky",
    "iconoclastic",
    "icy",
    "ideal",
    "idealistic",
    "identical",
    "identifiable",
    "idiosyncratic",
    "idiotic",
    "idyllic",
    "ignorable",
    "ignorant",
    "ill",
    "illegal",
    "illegible",
    "illegitimate",
    "illicit",
    "illiterate",
    "illogical",
    "illuminating",
    "illusive",
    "illustrious",
    "imaginable",
    "imaginary",
    "imaginative",
    "imitative",
    "immaculate",
    "immanent",
    "immature",
    "immeasurable",
    "immediate",
    "immense",
    "immensurable",
    "imminent",
    "immobile",
    "immodest",
    "immoral",
    "immortal",
    "immovable",
    "impartial",
    "impassable",
    "impassioned",
    "impatient",
    "impeccable",
    "impenetrable",
    "imperative",
    "imperceptible",
    "imperceptive",
    "imperfect",
    "imperial",
    "imperialistic",
    "impermeable",
    "impersonal",
    "impertinent",
    "impervious",
    "impetuous",
    "impish",
    "implausible",
    "implicit",
    "implosive",
    "impolite",
    "imponderable",
    "important",
    "imported",
    "imposing",
    "impossible",
    "impoverished",
    "impractical",
    "imprecise",
    "impressionable",
    "impressive",
    "improbable",
    "improper",
    "improvable",
    "improved",
    "improving",
    "imprudent",
    "impulsive",
    "impure",
    "inaccessible",
    "inaccurate",
    "inactive",
    "inadequate",
    "inadmissible",
    "inadvertent",
    "inadvisable",
    "inalienable",
    "inalterable",
    "inane",
    "inanimate",
    "inapplicable",
    "inappropriate",
    "inapt",
    "inarguable",
    "inarticulate",
    "inartistic",
    "inattentive",
    "inaudible",
    "inauspicious",
    "incalculable",
    "incandescent",
    "incapable",
    "incessant",
    "incidental",
    "inclusive",
    "incoherent",
    "incomparable",
    "incompatible",
    "incompetent",
    "incomplete",
    "incomprehensible",
    "inconceivable",
    "inconclusive",
    "incongruent",
    "incongruous",
    "inconsequential",
    "inconsiderable",
    "inconsiderate",
    "inconsistent",
    "inconsolable",
    "inconspicuous",
    "incontrovertible",
    "inconvenient",
    "incorrect",
    "incorrigible",
    "incorruptible",
    "increasing",
    "incredible",
    "incredulous",
    "incremental",
    "incurable",
    "indecent",
    "indecipherable",
    "indecisive",
    "indefensible",
    "indefinable",
    "indefinite",
    "indelible",
    "independent",
    "indescribable",
    "indestructible",
    "indeterminable",
    "indeterminate",
    "indicative",
    "indifferent",
    "indigenous",
    "indignant",
    "indirect",
    "indiscreet",
    "indiscriminate",
    "indispensable",
    "indisputable",
    "indistinct",
    "individual",
    "individualistic",
    "indivisible",
    "indomitable",
    "inductive",
    "indulgent",
    "industrial",
    "industrious",
    "ineffective",
    "ineffectual",
    "inefficient",
    "inelegant",
    "ineloquent",
    "inequitable",
    "inert",
    "inescapable",
    "inevitable",
    "inexact",
    "inexcusable",
    "inexhaustible",
    "inexpedient",
    "inexpensive",
    "inexplicable",
    "inexpressible",
    "inexpressive",
    "inextricable",
    "infallible",
    "infamous",
    "infantile",
    "infatuated",
    "infected",
    "infectious",
    "inferable",
    "inferior",
    "infernal",
    "infinite",
    "infinitesimal",
    "inflamed",
    "inflammable",
    "inflammatory",
    "inflatable",
    "inflated",
    "inflexible",
    "influential",
    "informal",
    "informative",
    "informed",
    "infrequent",
    "infuriated",
    "infuriating",
    "ingenious",
    "ingenuous",
    "inglorious",
    "ingratiating",
    "inhabitable",
    "inharmonious",
    "inherent",
    "inhibited",
    "inhospitable",
    "inhuman",
    "inhumane",
    "initial",
    "injudicious",
    "injured",
    "injurious",
    "innate",
    "inner",
    "innocent",
    "innocuous",
    "innovative",
    "innumerable",
    "inoffensive",
    "inoperable",
    "inoperative",
    "inopportune",
    "inordinate",
    "inorganic",
    "inquiring",
    "inquisitive",
    "insane",
    "insatiable",
    "inscrutable",
    "insecure",
    "insensible",
    "insensitive",
    "inseparable",
    "inside",
    "insidious",
    "insightful",
    "insignificant",
    "insincere",
    "insipid",
    "insistent",
    "insolent",
    "inspirational",
    "inspired",
    "inspiring",
    "instant",
    "instantaneous",
    "instinctive",
    "instinctual",
    "institutional",
    "instructive",
    "instrumental",
    "insubordinate",
    "insufferable",
    "insufficient",
    "insulted",
    "insulting",
    "insurable",
    "insurmountable",
    "intangible",
    "integral",
    "intellectual",
    "intelligent",
    "intelligible",
    "intended",
    "intense",
    "intensive",
    "intentional",
    "interactive",
    "interchangeable",
    "interdepartmental",
    "interdependent",
    "interested",
    "interesting",
    "interior",
    "intermediate",
    "intermittent",
    "internal",
    "international",
    "interpersonal",
    "interracial",
    "intestinal",
    "intimate",
    "intimidating",
    "intolerable",
    "intolerant",
    "intravenous",
    "intrepid",
    "intricate",
    "intrigued",
    "intriguing",
    "intrinsic",
    "introductory",
    "introspective",
    "introverted",
    "intrusive",
    "intuitive",
    "invalid",
    "invaluable",
    "invasive",
    "inventive",
    "invigorating",
    "invincible",
    "invisible",
    "invited",
    "inviting",
    "involuntary",
    "involved",
    "inward",
    "irascible",
    "irate",
    "iridescent",
    "irksome",
    "iron",
    "ironic",
    "irrational",
    "irreconcilable",
    "irrefutable",
    "irregular",
    "irrelative",
    "irrelevant",
    "irremovable",
    "irreparable",
    "irreplaceable",
    "irrepressible",
    "irresistible",
    "irresponsible",
    "irretrievably",
    "irreverent",
    "irreversible",
    "irrevocable",
    "irritable",
    "irritated",
    "irritating",
    "isolated",
    "itchy",
    "jabbering",
    "jaded",
    "jagged",
    "jarring",
    "jaundiced",
    "jazzy",
    "jealous",
    "jeering",
    "jerky",
    "jiggling",
    "jittery",
    "jobless",
    "jocular",
    "joint",
    "jolly",
    "jovial",
    "joyful",
    "joyless",
    "joyous",
    "jubilant",
    "judgmental",
    "judicious",
    "juicy",
    "jumbled",
    "jumpy",
    "junior",
    "just",
    "justifiable",
    "juvenile",
    "kaput",
    "keen",
    "key",
    "kind",
    "kindhearted",
    "kindly",
    "kinesthetic",
    "kingly",
    "kitchen",
    "knavish",
    "knightly",
    "knobbed",
    "knobby",
    "knotty",
    "knowable",
    "knowing",
    "knowledgeable",
    "known",
    "labored",
    "laborious",
    "lackadaisical",
    "lacking",
    "lacy",
    "lame",
    "lamentable",
    "languid",
    "languishing",
    "lanky",
    "larcenous",
    "large",
    "larger",
    "largest",
    "lascivious",
    "last",
    "lasting",
    "late",
    "latent",
    "later",
    "lateral",
    "latest",
    "latter",
    "laudable",
    "laughable",
    "laughing",
    "lavish",
    "lawful",
    "lawless",
    "lax",
    "lazy",
    "lead",
    "leading",
    "lean",
    "learnable",
    "learned",
    "leased",
    "least",
    "leather",
    "leathery",
    "lecherous",
    "leering",
    "left",
    "legal",
    "legendary",
    "legible",
    "legislative",
    "legitimate",
    "lengthy",
    "lenient",
    "less",
    "lesser",
    "lethal",
    "lethargic",
    "level",
    "liable",
    "libelous",
    "liberal",
    "licensed",
    "life",
    "lifeless",
    "lifelike",
    "lifelong",
    "light",
    "lighthearted",
    "likable",
    "likeable",
    "likely",
    "limber",
    "limited",
    "limitless",
    "limp",
    "limping",
    "linear",
    "lined",
    "lingering",
    "linguistic",
    "liquid",
    "listless",
    "literal",
    "literary",
    "literate",
    "lithe",
    "lithographic",
    "litigious",
    "little",
    "livable",
    "live",
    "lively",
    "livid",
    "living",
    "loathsome",
    "local",
    "locatable",
    "locked",
    "lofty",
    "logarithmic",
    "logical",
    "logistic",
    "lonely",
    "long",
    "longer",
    "longest",
    "longing",
    "loose",
    "lopsided",
    "loquacious",
    "lordly",
    "lost",
    "loud",
    "lousy",
    "loutish",
    "lovable",
    "loveable",
    "lovely",
    "loving",
    "low",
    "lower",
    "lowly",
    "loyal",
    "lucent",
    "lucid",
    "lucky",
    "lucrative",
    "ludicrous",
    "lukewarm",
    "lulling",
    "luminescent",
    "luminous",
    "lumpy",
    "lurid",
    "luscious",
    "lush",
    "lustrous",
    "luxurious",
    "lying",
    "lyrical",
    "macabre",
    "Machiavellian",
    "macho",
    "mad",
    "maddening",
    "magenta",
    "magic",
    "magical",
    "magnanimous",
    "magnetic",
    "magnificent",
    "maiden",
    "main",
    "maintainable",
    "majestic",
    "major",
    "makeable",
    "makeshift",
    "maladjusted",
    "male",
    "malevolent",
    "malicious",
    "malignant",
    "malleable",
    "mammoth",
    "manageable",
    "managerial",
    "mandatory",
    "maneuverable",
    "mangy",
    "maniacal",
    "manic",
    "manicured",
    "manipulative",
    "manual",
    "many,",
    "marbled",
    "marginal",
    "marked",
    "marketable",
    "married",
    "marvelous",
    "masked",
    "massive",
    "master",
    "masterful",
    "matchless",
    "material",
    "materialistic",
    "maternal",
    "mathematical",
    "matronly",
    "matted",
    "mature",
    "maximum",
    "meager",
    "mean",
    "meandering",
    "meaningful",
    "meaningless",
    "measly",
    "measurable",
    "meaty",
    "mechanical",
    "medical",
    "medicinal",
    "meditative",
    "medium",
    "meek",
    "melancholy",
    "mellow",
    "melodic",
    "melodious",
    "melodramatic",
    "melted",
    "memorable",
    "menacing",
    "menial",
    "mental",
    "merciful",
    "merciless",
    "mercurial",
    "mere",
    "merry",
    "messy",
    "metabolic",
    "metallic",
    "metaphoric",
    "meteoric",
    "meticulous",
    "microscopic",
    "microwaveable",
    "middle",
    "midweek",
    "mighty",
    "mild",
    "militant",
    "militaristic",
    "military",
    "milky",
    "mincing",
    "mindful",
    "mindless",
    "mini",
    "miniature",
    "minimal",
    "minimum",
    "minor",
    "minute",
    "miraculous",
    "mirthful",
    "miscellaneous",
    "mischievous",
    "miscreant",
    "miserable",
    "miserly",
    "misguided",
    "misleading",
    "mission",
    "mistaken",
    "mistrustful",
    "mistrusting",
    "misty",
    "mixed",
    "mnemonic",
    "moaning",
    "mobile",
    "mocking",
    "moderate",
    "modern",
    "modest",
    "modified",
    "modular",
    "moist",
    "moldy",
    "momentary",
    "momentous",
    "monetary",
    "monopolistic",
    "monosyllabic",
    "monotone",
    "monotonous",
    "monstrous",
    "monumental",
    "moody",
    "moral",
    "moralistic",
    "morbid",
    "mordant",
    "more",
    "moronic",
    "morose",
    "mortal",
    "mortified",
    "most",
    "mother",
    "motherly",
    "motionless",
    "motivated",
    "motivating",
    "motivational",
    "motor",
    "mountain",
    "mountainous",
    "mournful",
    "mouthwatering",
    "movable",
    "moved",
    "moving",
    "much",
    "muddled",
    "muddy",
    "muffled",
    "muggy",
    "multicultural",
    "multifaceted",
    "multipurpose",
    "multitalented",
    "mumbled",
    "mundane",
    "municipal",
    "murky",
    "muscular",
    "mushy",
    "musical",
    "musky",
    "musty",
    "mutative",
    "mute",
    "muted",
    "mutinous",
    "muttering",
    "mutual",
    "my",
    "myopic",
    "mysterious",
    "mystic",
    "mystical",
    "mystified",
    "mystifying",
    "mythical",
    "naive",
    "nameless",
    "narcissistic",
    "narrow",
    "nasal",
    "nasty",
    "national",
    "native",
    "natural",
    "naughty",
    "nauseating",
    "nauseous",
    "nautical",
    "navigable",
    "near",
    "nearby",
    "nearest",
    "nearsighted",
    "neat",
    "nebulous",
    "necessary",
    "needless",
    "needy",
    "nefarious",
    "negative",
    "neglected",
    "neglectful",
    "negligent",
    "negligible",
    "negotiable",
    "neighborly",
    "neither",
    "nervous",
    "neurological",
    "neurotic",
    "neutral",
    "new",
    "newest",
    "next",
    "nice",
    "nifty",
    "nightmarish",
    "nimble",
    "nine",
    "ninth",
    "nippy",
    "no",
    "noble",
    "nocturnal",
    "noiseless",
    "noisy",
    "nominal",
    "nonabrasive",
    "nonaggressive",
    "nonchalant",
    "noncommittal",
    "noncompetitive",
    "nonconsecutive",
    "nondescript",
    "nondestructive",
    "nonexclusive",
    "nonnegotiable",
    "nonproductive",
    "nonrefundable",
    "nonrenewable",
    "nonresponsive",
    "nonrestrictive",
    "nonreturnable",
    "nonsensical",
    "nonspecific",
    "nonstop",
    "nontransferable",
    "nonverbal",
    "nonviolent",
    "normal",
    "north",
    "northeast",
    "northerly",
    "northwest",
    "nostalgic",
    "nosy",
    "notable",
    "noticeable",
    "notorious",
    "novel",
    "noxious",
    "null",
    "numb",
    "numberless",
    "numbing",
    "numerable",
    "numeric",
    "numerous",
    "nutritional",
    "nutritious",
    "nutty",
    "oafish",
    "obedient",
    "obeisant",
    "obese",
    "objectionable",
    "objective",
    "obligatory",
    "obliging",
    "oblique",
    "oblivious",
    "oblong",
    "obnoxious",
    "obscene",
    "obscure",
    "observable",
    "observant",
    "obsessive",
    "obsolete",
    "obstinate",
    "obstructive",
    "obtainable",
    "obtrusive",
    "obtuse",
    "obvious",
    "occasional",
    "occupational",
    "occupied",
    "oceanic",
    "odd",
    "odiferous",
    "odious",
    "odorless",
    "odorous",
    "offbeat",
    "offensive",
    "offhanded",
    "official",
    "officious",
    "oily",
    "OK",
    "okay",
    "old",
    "older",
    "oldest",
    "ominous",
    "omniscient",
    "omnivorous",
    "one",
    "onerous",
    "only",
    "opaque",
    "open",
    "opened",
    "openhanded",
    "openhearted",
    "opening",
    "operable",
    "operatic",
    "operational",
    "operative",
    "opinionated",
    "opportune",
    "opportunistic",
    "opposable",
    "opposed",
    "opposing",
    "opposite",
    "oppressive",
    "optimal",
    "optimistic",
    "optional",
    "opulent",
    "oral",
    "orange",
    "ordinary",
    "organic",
    "organizational",
    "original",
    "ornamental",
    "ornate",
    "ornery",
    "orphaned",
    "orthopedic",
    "ossified",
    "ostentatious",
    "other",
    "otherwise",
    "our",
    "outer",
    "outermost",
    "outgoing",
    "outlandish",
    "outraged",
    "outrageous",
    "outside",
    "outspoken",
    "outstanding",
    "outward",
    "oval",
    "overactive",
    "overaggressive",
    "overall",
    "overambitious",
    "overassertive",
    "overbearing",
    "overcast",
    "overcautious",
    "overconfident",
    "overcritical",
    "overcrowded",
    "overemotional",
    "overenthusiastic",
    "overjoyed",
    "overoptimistic",
    "overpowering",
    "overpriced",
    "overprotective",
    "overqualified",
    "overrated",
    "oversensitive",
    "oversized",
    "overt",
    "overwhelmed",
    "overwhelming",
    "overworked",
    "overwrought",
    "overzealous",
    "own",
    "oxymoronic",
    "padded",
    "painful",
    "painless",
    "painstaking",
    "palatable",
    "palatial",
    "pale",
    "pallid",
    "palpable",
    "paltry",
    "pampered",
    "panicky",
    "panoramic",
    "paradoxical",
    "parallel",
    "paranormal",
    "parasitic",
    "parched",
    "pardonable",
    "parental",
    "parenthetic",
    "parking",
    "parsimonious",
    "partial",
    "particular",
    "partisan",
    "party",
    "passing",
    "passionate",
    "passive",
    "past",
    "pastoral",
    "patched",
    "patchy",
    "patented",
    "paternal",
    "paternalistic",
    "pathetic",
    "pathological",
    "patient",
    "patriotic",
    "patronizing",
    "patterned",
    "payable",
    "peaceable",
    "peaceful",
    "peculiar",
    "pedantic",
    "pedestrian",
    "peerless",
    "peeved",
    "peevish",
    "penetrable",
    "penetrating",
    "pensive",
    "peppery",
    "perceivable",
    "perceptible",
    "perceptive",
    "perceptual",
    "peremptory",
    "perennial",
    "perfect",
    "perfumed",
    "perilous",
    "period",
    "periodic",
    "peripheral",
    "perishable",
    "perky",
    "permanent",
    "permeable",
    "permissible",
    "permissive",
    "pernicious",
    "perpendicular",
    "perpetual",
    "perplexed",
    "perplexing",
    "persevering",
    "persistent",
    "personable",
    "personal",
    "persuasive",
    "pert",
    "pertinent",
    "perturbed",
    "perturbing",
    "pervasive",
    "perverse",
    "pessimistic",
    "petite",
    "pettish",
    "petty",
    "petulant",
    "pharmaceutical",
    "phenomenal",
    "philanthropic",
    "philosophical",
    "phobic",
    "phonemic",
    "phonetic",
    "phosphorescent",
    "photographic",
    "physical",
    "physiological",
    "picturesque",
    "piercing",
    "pigheaded",
    "pink",
    "pious",
    "piquant",
    "piteous",
    "pithy",
    "pitiful",
    "pitiless",
    "pivotal",
    "placid",
    "plaid",
    "plain",
    "plane",
    "planned",
    "plastic",
    "platonic",
    "plausible",
    "playful",
    "pleading",
    "pleasant",
    "pleased",
    "pleasing",
    "pleasurable",
    "plentiful",
    "pliable",
    "plodding",
    "plopping",
    "plucky",
    "plump",
    "pluralistic",
    "plus",
    "plush",
    "pneumatic",
    "poetic",
    "poignant",
    "pointless",
    "poised",
    "poisonous",
    "polished",
    "polite",
    "political",
    "polluted",
    "polyunsaturated",
    "pompous",
    "ponderous",
    "poor",
    "poorer",
    "poorest",
    "popping",
    "popular",
    "populous",
    "porous",
    "portable",
    "portly",
    "positive",
    "possessive",
    "possible",
    "posthoc",
    "posthumous",
    "postoperative",
    "potable",
    "potent",
    "potential",
    "powdery",
    "powerful",
    "powerless",
    "practical",
    "pragmatic",
    "praiseworthy",
    "precarious",
    "precious",
    "precipitous",
    "precise",
    "precocious",
    "preconceived",
    "predicative",
    "predictable",
    "predisposed",
    "predominant",
    "preeminent",
    "preemptive",
    "prefabricated",
    "preferable",
    "preferential",
    "pregnant",
    "prehistoric",
    "prejudiced",
    "prejudicial",
    "preliminary",
    "premature",
    "premeditated",
    "premium",
    "prenatal",
    "preoccupied",
    "preoperative",
    "preparative",
    "prepared",
    "preposterous",
    "prescriptive",
    "present",
    "presentable",
    "presidential",
    "pressing",
    "pressurized",
    "prestigious",
    "presumable",
    "presumptive",
    "presumptuous",
    "pretend",
    "pretentious",
    "pretty",
    "prevalent",
    "preventable",
    "preventative",
    "preventive",
    "previous",
    "priceless",
    "pricey",
    "prickly",
    "prim",
    "primary",
    "primitive",
    "primordial",
    "princely",
    "principal",
    "principled",
    "prior",
    "prissy",
    "pristine",
    "private",
    "prize",
    "prized",
    "proactive",
    "probabilistic",
    "probable",
    "problematic",
    "procedural",
    "prodigious",
    "productive",
    "profane",
    "professed",
    "professional",
    "professorial",
    "proficient",
    "profitable",
    "profound",
    "profuse",
    "programmable",
    "progressive",
    "prohibitive",
    "prolific",
    "prominent",
    "promised",
    "promising",
    "prompt",
    "pronounceable",
    "pronounced",
    "proof",
    "proper",
    "prophetic",
    "proportional",
    "proportionate",
    "proportioned",
    "prospective",
    "prosperous",
    "protective",
    "prototypical",
    "proud",
    "proverbial",
    "provisional",
    "provocative",
    "provoking",
    "proximal",
    "proximate",
    "prudent",
    "prudential",
    "prying",
    "psychedelic",
    "psychiatric",
    "psychological",
    "psychosomatic",
    "psychotic",
    "public",
    "puckish",
    "puffy",
    "pugnacious",
    "pumped",
    "punctual",
    "pungent",
    "punishable",
    "punitive",
    "puny",
    "pure",
    "purified",
    "puritanical",
    "purple",
    "purported",
    "purposeful",
    "purposeless",
    "purring",
    "pushy",
    "pusillanimous",
    "putrid",
    "puzzled",
    "puzzling",
    "pyrotechnic",
    "quackish",
    "quacky",
    "quaint",
    "qualified",
    "qualitative",
    "quality",
    "quantifiable",
    "quantitative",
    "quarrelsome",
    "queasy",
    "queenly",
    "querulous",
    "questionable",
    "quick",
    "quiet",
    "quintessential",
    "quirky",
    "quivering",
    "quizzical",
    "quotable",
    "rabid",
    "racial",
    "racist",
    "radiant",
    "radical",
    "radioactive",
    "ragged",
    "raging",
    "rainbowcolored",
    "rainy",
    "rakish",
    "rambling",
    "rambunctious",
    "rampageous",
    "rampant",
    "rancid",
    "rancorous",
    "random",
    "rank",
    "rapid",
    "rapturous",
    "rare",
    "rascally",
    "rash",
    "rasping",
    "raspy",
    "rational",
    "ratty",
    "ravenous",
    "raving",
    "ravishing",
    "raw",
    "reactive",
    "ready",
    "real",
    "realistic",
    "reasonable",
    "reassured",
    "reassuring",
    "rebel",
    "rebellious",
    "receding",
    "recent",
    "receptive",
    "recessive",
    "rechargeable",
    "reciprocal",
    "reckless",
    "reclusive",
    "recognizable",
    "recognized",
    "rectangular",
    "rectifiable",
    "recurrent",
    "recyclable",
    "red",
    "reddish",
    "redeemable",
    "redolent",
    "redundant",
    "referential",
    "refillable",
    "reflective",
    "refractive",
    "refreshing",
    "refundable",
    "refurbished",
    "refutable",
    "regal",
    "regional",
    "regretful",
    "regrettable",
    "regular",
    "reigning",
    "relatable",
    "relative",
    "relaxed",
    "relaxing",
    "relentless",
    "relevant",
    "reliable",
    "relieved",
    "religious",
    "reluctant",
    "remaining",
    "remarkable",
    "remedial",
    "reminiscent",
    "remorseful",
    "remorseless",
    "remote",
    "removable",
    "renegotiable",
    "renewable",
    "rented",
    "repairable",
    "repaired",
    "repeatable",
    "repeated",
    "repentant",
    "repetitious",
    "repetitive",
    "replaceable",
    "replicable",
    "reported",
    "reprehensible",
    "representative",
    "repressive",
    "reproachful",
    "reproductive",
    "republican",
    "repugnant",
    "repulsive",
    "reputable",
    "reputed",
    "rescued",
    "resealable",
    "resentful",
    "reserved",
    "resident",
    "residential",
    "residual",
    "resilient",
    "resolute",
    "resolvable",
    "resonant",
    "resounding",
    "resourceful",
    "respectable",
    "respectful",
    "respective",
    "responsible",
    "responsive",
    "rested",
    "restful",
    "restless",
    "restored",
    "restrained",
    "restrictive",
    "retired",
    "retroactive",
    "retrogressive",
    "retrospective",
    "reusable",
    "revamped",
    "revealing",
    "revengeful",
    "reverent",
    "reverential",
    "reverse",
    "reversible",
    "reviewable",
    "reviled",
    "revisable",
    "revised",
    "revocable",
    "revolting",
    "revolutionary",
    "rewarding",
    "rhetorical",
    "rhythmic",
    "rich",
    "richer",
    "richest",
    "ridiculing",
    "ridiculous",
    "right",
    "righteous",
    "rightful",
    "rigid",
    "rigorous",
    "ringing",
    "riotous",
    "ripe",
    "rippling",
    "risky",
    "ritualistic",
    "ritzy",
    "riveting",
    "roaring",
    "roasted",
    "robotic",
    "robust",
    "rocketing",
    "roguish",
    "romantic",
    "roomy",
    "rosy",
    "rotating",
    "rotten",
    "rotting",
    "rotund",
    "rough",
    "round",
    "roundtable",
    "rousing",
    "routine",
    "rowdy",
    "royal",
    "ruddy",
    "rude",
    "rudimentary",
    "rueful",
    "rugged",
    "ruined",
    "ruinous",
    "rumbling",
    "rumpled",
    "ruptured",
    "rural",
    "rusted",
    "rustic",
    "rustling",
    "rusty",
    "ruthless",
    "rutted",
    "saccharin",
    "sacred",
    "sacrificial",
    "sacrilegious",
    "sad",
    "saddened",
    "safe",
    "saintly",
    "salacious",
    "salient",
    "salt",
    "salted",
    "salty",
    "salvageable",
    "salvaged",
    "same",
    "sanctimonious",
    "sandy",
    "sane",
    "sanguine",
    "sanitary",
    "sappy",
    "sarcastic",
    "sardonic",
    "sassy",
    "satin",
    "satiny",
    "satiric",
    "satirical",
    "satisfactory",
    "satisfied",
    "satisfying",
    "saucy",
    "savage",
    "savory",
    "savvy",
    "scalding",
    "scaly",
    "scandalous",
    "scant",
    "scanty",
    "scarce",
    "scared",
    "scarred",
    "scary",
    "scathing",
    "scattered",
    "scenic",
    "scented",
    "scheduled",
    "schematic",
    "scholarly",
    "scholastic",
    "scientific",
    "scintillating",
    "scorching",
    "scornful",
    "scrabbled",
    "scraggly",
    "scrappy",
    "scratched",
    "scratchy",
    "scrawny",
    "screaming",
    "screeching",
    "scribbled",
    "scriptural",
    "scruffy",
    "scrumptious",
    "scrupulous",
    "sculpted",
    "sculptural",
    "scummy",
    "sea",
    "sealed",
    "seamless",
    "searching",
    "searing",
    "seasick",
    "seasonable",
    "seasonal",
    "secluded",
    "second",
    "secondary",
    "secret",
    "secretive",
    "secular",
    "secure",
    "secured",
    "sedate",
    "seditious",
    "seductive",
    "seedy",
    "seeming",
    "seemly",
    "seething",
    "seismic",
    "select",
    "selected",
    "selective",
    "selfish",
    "selfless",
    "sellable",
    "semiconscious",
    "semiofficial",
    "semiprecious",
    "semiprofessional",
    "senior",
    "sensational",
    "senseless",
    "sensible",
    "sensitive",
    "sensual",
    "sensuous",
    "sentimental",
    "separate",
    "sequential",
    "serendipitous",
    "serene",
    "serial",
    "serious",
    "serrated",
    "serviceable",
    "seven",
    "seventh",
    "several",
    "severe",
    "shabbiest",
    "shabby",
    "shaded",
    "shadowed",
    "shadowy",
    "shady",
    "shaggy",
    "shaky",
    "shallow",
    "shamefaced",
    "shameful",
    "shameless",
    "shapeless",
    "shapely",
    "sharp",
    "sharpened",
    "shattered",
    "shattering",
    "sheepish",
    "sheer",
    "sheltered",
    "shifty",
    "shimmering",
    "shining",
    "shiny",
    "shivering",
    "shivery",
    "shocked",
    "shocking",
    "shoddy",
    "short",
    "shortsighted",
    "showy",
    "shrewd",
    "shrieking",
    "shrill",
    "shut",
    "shy",
    "sick",
    "sickened",
    "sickening",
    "sickly",
    "signed",
    "significant",
    "silent",
    "silky",
    "silly",
    "silver",
    "simian",
    "similar",
    "simple",
    "simpleminded",
    "simplified",
    "simplistic",
    "simultaneous",
    "sincere",
    "sinful",
    "single",
    "singular",
    "sinister",
    "sinuous",
    "sisterly",
    "six",
    "sixth",
    "sizable",
    "sizzling",
    "skeptical",
    "sketchy",
    "skilled",
    "skillful",
    "skimpy",
    "skinny",
    "skittish",
    "slanderous",
    "slanted",
    "slanting",
    "sleek",
    "sleeping",
    "sleepless",
    "sleepy",
    "slender",
    "slick",
    "slight",
    "slim",
    "slimy",
    "slippery",
    "sloped",
    "sloping",
    "sloppy",
    "slothful",
    "slow",
    "sluggish",
    "slushy",
    "sly",
    "small",
    "smaller",
    "smallest",
    "smarmy",
    "smart",
    "smarter",
    "smartest",
    "smashing",
    "smeared",
    "smelly",
    "smiling",
    "smoggy",
    "smoked",
    "smoky",
    "smooth",
    "smothering",
    "smudged",
    "smug",
    "snapping",
    "snappish",
    "snappy",
    "snarling",
    "sneaky",
    "snide",
    "snippy",
    "snobbish",
    "snoopy",
    "snooty",
    "snoring",
    "snotty",
    "snug",
    "snuggly",
    "soaked",
    "soaking",
    "soakingwet",
    "soaring",
    "sober",
    "sociable",
    "social",
    "socialist",
    "sociological",
    "soft",
    "softhearted",
    "soggy",
    "solar",
    "soldierly",
    "sole",
    "solemn",
    "solicitous",
    "solid",
    "solitary",
    "somatic",
    "somber",
    "some,",
    "sonic",
    "sonly",
    "soothed",
    "soothing",
    "sophisticated",
    "sordid",
    "sore",
    "sorrowful",
    "sorry",
    "soulful",
    "soulless",
    "soundless",
    "sour",
    "south",
    "southeasterly",
    "southern",
    "southwestern",
    "spacious",
    "spare",
    "sparing",
    "sparkling",
    "sparkly",
    "sparse",
    "spasmodic",
    "spastic",
    "spatial",
    "spattered",
    "special",
    "specialist",
    "specialized",
    "specific",
    "speckled",
    "spectacular",
    "spectral",
    "speculative",
    "speechless",
    "speedy",
    "spellbinding",
    "spendthrift",
    "spherical",
    "spicy",
    "spiffy",
    "spiky",
    "spinal",
    "spineless",
    "spiral",
    "spiraled",
    "spirited",
    "spiritless",
    "spiritual",
    "spiteful",
    "splashing",
    "splashy",
    "splattered",
    "splendid",
    "splintered",
    "spoiled",
    "spoken",
    "spongy",
    "spontaneous",
    "spooky",
    "sporadic",
    "sporting",
    "sportsmanly",
    "spotless",
    "spotted",
    "spotty",
    "springy",
    "sprite",
    "spry",
    "spurious",
    "squalid",
    "squandered",
    "square",
    "squashed",
    "squashy",
    "squatting",
    "squawking",
    "squealing",
    "squeamish",
    "squeezable",
    "squiggly",
    "squirming",
    "squirrelly",
    "stable",
    "stackable",
    "stacked",
    "staggering",
    "stagnant",
    "stained",
    "stale",
    "stanch",
    "standard",
    "standing",
    "standoffish",
    "starched",
    "stark",
    "startled",
    "startling",
    "starving",
    "stately",
    "static",
    "statistical",
    "statuesque",
    "status",
    "statutory",
    "staunch",
    "steadfast",
    "steady",
    "stealth",
    "steaming",
    "steamy",
    "steel",
    "steely",
    "steep",
    "stereophonic",
    "stereotyped",
    "stereotypical",
    "sterile",
    "stern",
    "sticky",
    "stiff",
    "stifled",
    "stifling",
    "stigmatic",
    "still",
    "stilled",
    "stilted",
    "stimulating",
    "stinging",
    "stingy",
    "stinking",
    "stinky",
    "stirring",
    "stock",
    "stodgy",
    "stoic",
    "stony",
    "stormy",
    "stout",
    "straggly",
    "straight",
    "straightforward",
    "stranded",
    "strange",
    "strategic",
    "streaked",
    "street",
    "strenuous",
    "stressful",
    "stretchy",
    "strict",
    "strident",
    "striking",
    "stringent",
    "striped",
    "strong",
    "stronger",
    "strongest",
    "structural",
    "stubborn",
    "stubby",
    "studied",
    "studious",
    "stuffed",
    "stuffy",
    "stumbling",
    "stunned",
    "stunning",
    "stupendous",
    "sturdy",
    "stuttering",
    "stylish",
    "stylistic",
    "suave",
    "subconscious",
    "subdued",
    "subject",
    "subjective",
    "sublime",
    "subliminal",
    "submissive",
    "subordinate",
    "subsequent",
    "subservient",
    "substantial",
    "substantiated",
    "substitute",
    "subterranean",
    "subtitled",
    "subtle",
    "subversive",
    "successful",
    "successive",
    "succinct",
    "succulent",
    "such",
    "sudden",
    "suffering",
    "sufficient",
    "sugary",
    "suggestive",
    "suitable",
    "sulky",
    "sullen",
    "sumptuous",
    "sunny",
    "super",
    "superabundant",
    "superb",
    "supercilious",
    "superficial",
    "superhuman",
    "superior",
    "superlative",
    "supernatural",
    "supersensitive",
    "supersonic",
    "superstitious",
    "supple",
    "supportive",
    "supposed",
    "suppressive",
    "supreme",
    "sure",
    "surgical",
    "surly",
    "surmountable",
    "surprised",
    "surprising",
    "surrealistic",
    "survivable",
    "susceptible",
    "suspected",
    "suspicious",
    "sustainable",
    "swaggering",
    "swanky",
    "swaying",
    "sweaty",
    "sweeping",
    "sweet",
    "sweltering",
    "swift",
    "swimming",
    "swinish",
    "swishing",
    "swollen",
    "swooping",
    "syllabic",
    "syllogistic",
    "symbiotic",
    "symbolic",
    "symmetrical",
    "sympathetic",
    "symptomatic",
    "synergistic",
    "synonymous",
    "syntactic",
    "synthetic",
    "systematic",
    "taboo",
    "tacit",
    "tacky",
    "tactful",
    "tactical",
    "tactless",
    "tactual",
    "tainted",
    "talented",
    "talkative",
    "tall",
    "taller",
    "tallest",
    "tame",
    "tamed",
    "tan",
    "tangential",
    "tangible",
    "tangled",
    "tangy",
    "tanned",
    "tantalizing",
    "tapered",
    "tardy",
    "targeted",
    "tarnished",
    "tart",
    "tasteful",
    "tasteless",
    "tasty",
    "tattered",
    "taunting",
    "taut",
    "taxing",
    "teachable",
    "tearful",
    "tearing",
    "teasing",
    "technical",
    "technological",
    "tectonic",
    "tedious",
    "teenage",
    "teensy",
    "teeny",
    "telegraphic",
    "telekinetic",
    "telepathic",
    "telephonic",
    "telescopic",
    "telling",
    "temperamental",
    "temperate",
    "tempestuous",
    "temporary",
    "tempted",
    "tempting",
    "ten",
    "tenable",
    "tenacious",
    "tender",
    "tenderhearted",
    "tense",
    "tentative",
    "tenth",
    "tenuous",
    "tepid",
    "terminal",
    "terrestrial",
    "terrible",
    "terrific",
    "terrified",
    "terrifying",
    "territorial",
    "terse",
    "tested",
    "testy",
    "tetchy",
    "textual",
    "textural",
    "thankful",
    "thankless",
    "that",
    "the",
    "theatrical",
    "their",
    "thematic",
    "theological",
    "theoretical",
    "therapeutic",
    "thermal",
    "these",
    "thick",
    "thievish",
    "thin",
    "thinkable",
    "third",
    "thirsty",
    "this",
    "thorny",
    "thorough",
    "those",
    "thoughtful",
    "thoughtless",
    "thrashed",
    "threatened",
    "threatening",
    "three",
    "thriftless",
    "thrifty",
    "thrilled",
    "thrilling",
    "throbbing",
    "thumping",
    "thundering",
    "thunderous",
    "ticking",
    "tickling",
    "ticklish",
    "tidal",
    "tidy",
    "tight",
    "tightfisted",
    "time",
    "timeless",
    "timely",
    "timid",
    "timorous",
    "tiny",
    "tipsy",
    "tired",
    "tireless",
    "tiresome",
    "tiring",
    "tolerable",
    "tolerant",
    "tonal",
    "toneless",
    "toothsome",
    "toothy",
    "top",
    "topical",
    "topographical",
    "tormented",
    "torpid",
    "torrential",
    "torrid",
    "torturous",
    "total",
    "touched",
    "touching",
    "touchy",
    "tough",
    "towering",
    "toxic",
    "traditional",
    "tragic",
    "trainable",
    "trained",
    "training",
    "traitorous",
    "tranquil",
    "transcendent",
    "transcendental",
    "transformational",
    "transformative",
    "transformed",
    "transient",
    "transitional",
    "transitory",
    "translucent",
    "transparent",
    "transplanted",
    "trapped",
    "trashed",
    "trashy",
    "traumatic",
    "treacherous",
    "treasonable",
    "treasonous",
    "treasured",
    "treatable",
    "tremendous",
    "tremulous",
    "trenchant",
    "trendy",
    "triangular",
    "tribal",
    "trick",
    "tricky",
    "trim",
    "tripping",
    "trite",
    "triumphant",
    "trivial",
    "tropical",
    "troubled",
    "troublesome",
    "troubling",
    "truculent",
    "true",
    "trusted",
    "trustful",
    "trusting",
    "trustworthy",
    "trusty",
    "truthful",
    "trying",
    "tumultuous",
    "tuneful",
    "tuneless",
    "turbulent",
    "twinkling",
    "twinkly",
    "twisted",
    "twitchy",
    "two",
    "typical",
    "tyrannical",
    "tyrannous",
    "ubiquitous",
    "ugly",
    "ultimate",
    "ultraconservative",
    "ultrasensitive",
    "ultrasonic",
    "ultraviolet",
    "unabashed",
    "unabated",
    "unable",
    "unacceptable",
    "unaccompanied",
    "unaccountable",
    "unaccustomed",
    "unacknowledged",
    "unadorned",
    "unadulterated",
    "unadventurous",
    "unadvised",
    "unaffected",
    "unaffordable",
    "unafraid",
    "unaggressive",
    "unaided",
    "unalienable",
    "unalterable",
    "unaltered",
    "unambiguous",
    "unanimous",
    "unannounced",
    "unanswerable",
    "unanticipated",
    "unapologetic",
    "unappealing",
    "unappetizing",
    "unappreciative",
    "unapproachable",
    "unashamed",
    "unassailable",
    "unassertive",
    "unassisted",
    "unattached",
    "unattainable",
    "unattractive",
    "unauthorized",
    "unavailable",
    "unavailing",
    "unavoidable",
    "unbalanced",
    "unbearable",
    "unbeatable",
    "unbeaten",
    "unbecoming",
    "unbelievable",
    "unbelieving",
    "unbendable",
    "unbending",
    "unbiased",
    "unblemished",
    "unblinking",
    "unblushing",
    "unbounded",
    "unbreakable",
    "unbridled",
    "uncanny",
    "uncaring",
    "unceasing",
    "unceremonious",
    "uncertain",
    "unchangeable",
    "unchanging",
    "uncharacteristic",
    "uncharitable",
    "uncharted",
    "uncivil",
    "uncivilized",
    "unclassified",
    "unclean",
    "uncluttered",
    "uncomely",
    "uncomfortable",
    "uncommitted",
    "uncommon",
    "uncommunicative",
    "uncomplaining",
    "uncomprehending",
    "uncompromising",
    "unconcerned",
    "unconditional",
    "unconfirmed",
    "unconquerable",
    "unconscionable",
    "unconscious",
    "unconstitutional",
    "unconstrained",
    "unconstructive",
    "uncontainable",
    "uncontrollable",
    "unconventional",
    "unconvinced",
    "unconvincing",
    "uncooked",
    "uncooperative",
    "uncoordinated",
    "uncouth",
    "uncovered",
    "uncreative",
    "uncritical",
    "undamaged",
    "undated",
    "undaunted",
    "undeclared",
    "undefeated",
    "undefined",
    "undemocratic",
    "undeniable",
    "undependable",
    "underdeveloped",
    "underfunded",
    "underhanded",
    "underprivileged",
    "understandable",
    "understanding",
    "understated",
    "understood",
    "undeserved",
    "undesirable",
    "undetected",
    "undeterred",
    "undeveloped",
    "undeviating",
    "undifferentiated",
    "undignified",
    "undiminished",
    "undiplomatic",
    "undisciplined",
    "undiscovered",
    "undisguised",
    "undisputed",
    "undistinguished",
    "undivided",
    "undoubted",
    "unearthly",
    "uneasy",
    "uneducated",
    "unemotional",
    "unemployed",
    "unencumbered",
    "unending",
    "unendurable",
    "unenforceable",
    "unenthusiastic",
    "unenviable",
    "unequal",
    "unequaled",
    "unequivocal",
    "unerring",
    "uneven",
    "uneventful",
    "unexceptional",
    "unexcited",
    "unexpected",
    "unexplainable",
    "unexplored",
    "unexpressive",
    "unfailing",
    "unfair",
    "unfaithful",
    "unfaltering",
    "unfamiliar",
    "unfashionable",
    "unfathomable",
    "unfavorable",
    "unfeeling",
    "unfettered",
    "unfilled",
    "unflagging",
    "unflappable",
    "unflattering",
    "unflinching",
    "unfocused",
    "unforeseeable",
    "unforgettable",
    "unforgivable",
    "unforgiving",
    "unfortunate",
    "unfriendly",
    "unfulfilled",
    "ungallant",
    "ungenerous",
    "ungentlemanly",
    "unglamorous",
    "ungraceful",
    "ungracious",
    "ungrateful",
    "unguarded",
    "unhandsome",
    "unhappy",
    "unharmed",
    "unhealthy",
    "unheated",
    "unheeded",
    "unhelpful",
    "unhesitating",
    "unhurried",
    "uniform",
    "unilateral",
    "unimaginable",
    "unimaginative",
    "unimpeachable",
    "unimpeded",
    "unimpressive",
    "unincorporated",
    "uninformed",
    "uninhabitable",
    "uninhibited",
    "uninitiated",
    "uninjured",
    "uninspired",
    "uninsurable",
    "unintelligent",
    "unintelligible",
    "unintended",
    "unintentional",
    "uninterested",
    "uninterrupted",
    "uninvited",
    "unique",
    "united",
    "universal",
    "unjust",
    "unjustifiable",
    "unkempt",
    "unkind",
    "unknowing",
    "unknown",
    "unlawful",
    "unlicensed",
    "unlikable",
    "unlikely",
    "unlivable",
    "unloved",
    "unlucky",
    "unmanageable",
    "unmanly",
    "unmanned",
    "unmarketable",
    "unmasked",
    "unmatched",
    "unmemorable",
    "unmentionable",
    "unmerciful",
    "unmistakable",
    "unmitigated",
    "unmodified",
    "unmotivated",
    "unnatural",
    "unnecessary",
    "unnerved",
    "unnerving",
    "unnoticeable",
    "unobserved",
    "unobtainable",
    "unobtrusive",
    "unofficial",
    "unopened",
    "unopposed",
    "unorthodox",
    "unostentatious",
    "unpalatable",
    "unpardonable",
    "unpersuasive",
    "unperturbed",
    "unplanned",
    "unpleasant",
    "unprecedented",
    "unpredictable",
    "unpretentious",
    "unprincipled",
    "unproductive",
    "unprofessional",
    "unprofitable",
    "unpromising",
    "unpronounceable",
    "unprovoked",
    "unqualified",
    "unquantifiable",
    "unquenchable",
    "unquestionable",
    "unquestioned",
    "unquestioning",
    "unraveled",
    "unreachable",
    "unreadable",
    "unrealistic",
    "unrealized",
    "unreasonable",
    "unreceptive",
    "unrecognizable",
    "unrecognized",
    "unredeemable",
    "unregulated",
    "unrelenting",
    "unreliable",
    "unremarkable",
    "unremitting",
    "unrepentant",
    "unrepresentative",
    "unrepresented",
    "unreserved",
    "unrespectable",
    "unresponsive",
    "unrestrained",
    "unripe",
    "unrivaled",
    "unromantic",
    "unruffled",
    "unruly",
    "unsafe",
    "unsalvageable",
    "unsatisfactory",
    "unsatisfied",
    "unscheduled",
    "unscholarly",
    "unscientific",
    "unscrupulous",
    "unseasonable",
    "unseemly",
    "unselfish",
    "unsettled",
    "unsettling",
    "unshakable",
    "unshapely",
    "unsightly",
    "unsigned",
    "unsinkable",
    "unskilled",
    "unsociable",
    "unsolicited",
    "unsolvable",
    "unsolved",
    "unsophisticated",
    "unsound",
    "unsparing",
    "unspeakable",
    "unspoiled",
    "unstable",
    "unstated",
    "unsteady",
    "unstoppable",
    "unstressed",
    "unstructured",
    "unsubstantial",
    "unsubstantiated",
    "unsuccessful",
    "unsuitable",
    "unsuited",
    "unsupervised",
    "unsupported",
    "unsure",
    "unsurpassable",
    "unsurpassed",
    "unsurprising",
    "unsuspected",
    "unsuspecting",
    "unsustainable",
    "unsympathetic",
    "unsystematic",
    "untainted",
    "untamable",
    "untamed",
    "untapped",
    "untenable",
    "untested",
    "unthinkable",
    "unthinking",
    "untidy",
    "untimely",
    "untitled",
    "untouchable",
    "untraditional",
    "untrained",
    "untried",
    "untroubled",
    "untrustworthy",
    "untruthful",
    "unused",
    "unusual",
    "unverified",
    "unwary",
    "unwashed",
    "unwatchable",
    "unwavering",
    "unwholesome",
    "unwieldy",
    "unwilling",
    "unwise",
    "unwitting",
    "unworkable",
    "unworldly",
    "unworthy",
    "unwritten",
    "unyielding",
    "upbeat",
    "upmost",
    "upper",
    "uppity",
    "upright",
    "uproarious",
    "upset",
    "upsetting",
    "upstairs",
    "uptight",
    "upward",
    "urbane",
    "urgent",
    "usable",
    "used",
    "useful",
    "useless",
    "usual",
    "utilitarian",
    "utopian",
    "utter",
    "uttermost",
    "vacant",
    "vacillating",
    "vacuous",
    "vagabond",
    "vagrant",
    "vague",
    "vain",
    "valiant",
    "valid",
    "valorous",
    "valuable",
    "vanishing",
    "vapid",
    "vaporous",
    "variable",
    "varied",
    "various",
    "varying",
    "vast",
    "vegetable",
    "vegetarian",
    "vegetative",
    "vehement",
    "velvety",
    "venal",
    "venerable",
    "vengeful",
    "venomous",
    "venturesome",
    "venturous",
    "veracious",
    "verbal",
    "verbose",
    "verdant",
    "verifiable",
    "verified",
    "veritable",
    "vernacular",
    "versatile",
    "versed",
    "vertical",
    "very",
    "vexed",
    "vexing",
    "viable",
    "vibrant",
    "vibrating",
    "vicarious",
    "vicious",
    "victorious",
    "vigilant",
    "vigorous",
    "vile",
    "villainous",
    "vindictive",
    "vinegary",
    "violent",
    "violet",
    "viperous",
    "viral",
    "virtual",
    "virtuous",
    "virulent",
    "visceral",
    "viscous",
    "visible",
    "visionary",
    "visual",
    "vital",
    "vitriolic",
    "vivacious",
    "vivid",
    "vocal",
    "vocational",
    "voiceless",
    "volatile",
    "volcanic",
    "voluminous",
    "voluntary",
    "voluptuous",
    "voracious",
    "vulgar",
    "vulnerable",
    "wacky",
    "wailing",
    "waiting",
    "wakeful",
    "wandering",
    "wanting",
    "wanton",
    "warlike",
    "warm",
    "warmest",
    "warning",
    "warring",
    "wary",
    "waspish",
    "waste",
    "wasted",
    "wasteful",
    "watchful",
    "waterlogged",
    "waterproof",
    "watertight",
    "watery",
    "wavering",
    "wax",
    "waxen",
    "weak",
    "weakened",
    "wealthy",
    "wearisome",
    "weary",
    "wee",
    "weedy",
    "weekly",
    "weightless",
    "weighty",
    "weird",
    "welcoming",
    "well",
    "west",
    "western",
    "wet",
    "what",
    "wheezing",
    "which",
    "whimpering",
    "whimsical",
    "whining",
    "whispering",
    "whistling",
    "white",
    "whole",
    "wholehearted",
    "wholesale",
    "wholesome",
    "whooping",
    "whopping",
    "whose",
    "wicked",
    "wide",
    "widespread",
    "wiggly",
    "wild",
    "willful",
    "willing",
    "wily",
    "windy",
    "winning",
    "winsome",
    "winter",
    "wintery",
    "wiry",
    "wise",
    "wishful",
    "wispy",
    "wistful",
    "withering",
    "witless",
    "witty",
    "wizardly",
    "wobbly",
    "woeful",
    "wolfish",
    "wonderful",
    "wondrous",
    "wonted",
    "wood",
    "wooden",
    "wooing",
    "wool",
    "woolen",
    "woozy",
    "wordless",
    "wordy",
    "work",
    "workable",
    "working",
    "worldly",
    "worn",
    "worndown",
    "wornout",
    "worried",
    "worrisome",
    "worrying",
    "worse",
    "worshipful",
    "worst",
    "worth",
    "worthless",
    "worthwhile",
    "worthy",
    "wounding",
    "wrathful",
    "wrenching",
    "wretched",
    "wriggling",
    "wriggly",
    "wrinkled",
    "wrinkly",
    "written",
    "wrong",
    "wrongful",
    "wry",
    "yawning",
    "yearly",
    "yearning",
    "yellow",
    "yelping",
    "yielding",
    "young",
    "younger",
    "youngest",
    "youthful",
    "yummy",
    "zany",
    "zealous",
    "zestful",
    "zesty",
    "zippy",
    "zonked",
    "zoological",
]
