const FACTS = [
    {
        "id": "1",
        "title": "Calories Burned",
        "description": "Banging your head against a wall for one hour burns 150 calories.",
        "sourceReference": "https://www.thefactsite.com/top-100-random-funny-facts/",
        "proofLink": "https://www.thefactsite.com/top-100-random-funny-facts/",
        "tags": [
            "Humans"
        ],
        "factCheckStatus": "false",
        "author": "test-username",
        "authorImg": "",
        "funRating": 50,
        "numPositiveRatings": 5,
        "datePosted": "1/1/2020",
        "numTotalRatings": 10
    },
    {
        "id": "2",
        "title": "Snakes!",
        "description": "Snakes can help predict earthquakes. They can sense a coming earthquake from 75 miles away (121 km), up to five days before it happens.",
        "sourceReference": "https://www.thefactsite.com/top-100-random-funny-facts/",
        "proofLink": "https://www.thefactsite.com/top-100-random-funny-facts/",
        "tags": [
            "Animals",
            "Earth",
            "Weather"
        ],
        "factCheckStatus": "true",
        "author": "test-username",
        "authorImg": "",
        "funRating": 75,
        "numPositiveRatings": 15,
        "datePosted": "1/1/2020",
        "numTotalRatings": 20
    },
    {
        "id": "3",
        "title": "Murder Crows",
        "description": "A flock of crows is known as a murder. Some might say that a group of two crows is an attempted murder!",
        "sourceReference": "https://www.thefactsite.com/top-100-random-funny-facts/",
        "proofLink": "https://www.thefactsite.com/top-100-random-funny-facts/",
        "tags": [],
        "factCheckStatus": "plausible",
        "author": "test-username",
        "authorImg": "",
        "funRating": 80,
        "numPositiveRatings": 4,
        "datePosted": "1/1/2020",
        "numTotalRatings": 5
    },
    {
        "id": "4",
        "title": "Eradicated diseases",
        "description": "So far, two diseases have successfully been eradicated: smallpox and rinderpest.",
        "sourceReference": "https://www.thefactsite.com/top-100-random-funny-facts/",
        "proofLink": "https://www.thefactsite.com/top-100-random-funny-facts/",
        "tags": [],
        "factCheckStatus": "",
        "author": "test-username",
        "authorImg": "",
        "funRating": 100,
        "numPositiveRatings": 50,
        "datePosted": "1/1/2020",
        "numTotalRatings": 50
    }
]

export default FACTS