import React from 'react'
import { Router } from '@reach/router'
import Layout from '../components/layout'
import Details from '../components/Details'
import Login from '../components/Login'
import SignUp from '../components/SignUp'
import PrivateRoute from '../components/PrivateRoute'
import FactDetails from '../components/fun-facts/Details/FactDetails'
import AdministrateFact from '../components/fun-facts/Admin/AdministrateFact'
import ModeratorDashboard from '../components/moderator/ModeratorDashboard'
import PrivateModRoute from '../components/PrivateModRoute'
import NotFound from './404'

const App = () => (
    <Layout>
        <Router>
            <PrivateRoute path="/app/profile" component={Details} />
            <PrivateModRoute
                path="/app/moderator"
                component={ModeratorDashboard}
            />
            <PrivateRoute
                path="/app/facts/create"
                component={AdministrateFact}
                newFact={true}
            />
            <PrivateRoute
                path="/app/facts/:factId/edit"
                component={AdministrateFact}
                newFact={false}
            />
            <FactDetails path="/app/facts/:factId" />
            <Login path="/app/login" />
            <SignUp path="/app/signup" />
            <NotFound default />
        </Router>
    </Layout>
)

export default App
