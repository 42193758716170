import React, { useState, useEffect } from 'react'
import CancelSvg from '../icons/CancelSvg'

const RemovableTagList = props => {
    const [tags, setTags] = useState(props.tags)
    const [input, setInput] = useState('')
    const [error, setError] = useState(undefined)

    const mockLoadingTags = ['1', '2', '3']

    useEffect(() => {
        setTags(props.tags)
    }, [props.tags])
    const updateValue = event => {
        setInput(event.target.value)
    }

    const removeTag = tagToRemove => {
        const newTags = tags.filter(tag => tag !== tagToRemove)
        setTags(newTags)
        props.setValue('tags', newTags)
    }

    const isDuplicateValue = val => {
        return tags.includes(val)
    }

    const addTag = event => {
        if (input === '') {
            return
        }

        if (isDuplicateValue(input)) {
            setError(`${input} is already a tag`)
        } else {
            const newTags = [input, ...tags]
            setError(undefined)
            setTags(newTags)
            props.setValue('tags', newTags)
            setInput('')
        }
    }

    return (
        <div className="flex flex-wrap flex-col flex-grow">
            <div className="flex flex-row">
                {props.loading ? (
                    <div className="rounded h-10 w-full animate-pulse bg-gray-300"></div>
                ) : (
                    <>
                        <input
                            id={props.name}
                            value={input}
                            name={props.name}
                            onChange={updateValue}
                            className="flex-1 form-input border block min-w-0 rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                        <span className="ml-3 inline-flex rounded-md shadow-sm">
                            <button
                                type="button"
                                onClick={addTag}
                                className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-ffpurple-500 hover:bg-ffpurple-400 focus:outline-none focus:border-ffpurple-700 focus:ring-ffpurple active:bg-ffpurple-700 transition duration-150 ease-in-out"
                            >
                                Add Tag
                            </button>
                        </span>
                    </>
                )}
            </div>
            {
                error && (
                    <div className="max-w-xl text-xs font-medium text-red-500">
                        {error}
                    </div>
                )
            }
            <div className="">
                {props.loading ? (
                    <>
                        {mockLoadingTags && mockLoadingTags.map(tag => (
                            <span
                                key={tag}
                                className="inline-flex mr-1 mt-1 w-20 h-5 animate-pulse items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-ffyellow text-gray-500"
                            >
                            </span>
                        ))}
                    </>
                ) : (
                    <>
                        {tags && tags.map(tag => (
                            <span
                                key={tag}
                                className="inline-flex mr-1 items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-ffyellow text-gray-500"
                            >
                                {tag}
                                <button
                                    type="button"
                                    className="flex-shrink-0 ml-1.5 inline-flex text-gray-500 focus:outline-none focus:text-gray-900"
                                    aria-label="Remove small badge"
                                    onClick={() => {
                                        removeTag(tag)
                                    }}
                                >
                                    <CancelSvg />
                                </button>
                            </span>
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}

export default RemovableTagList
