import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import { Link } from 'gatsby'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify'
import { ADJECTIVES } from '../utils/UsernameConstants/Adjectives'
import { NOUNS } from '../utils/UsernameConstants/Nouns'
import logo from './icons/funFactLogo/logo.png'

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email address is required')
        .email('Email must be valid'),
    username: Yup.string()
        .required('Username is required')
        .min(4, 'Username must be at least 4 characters'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match')
});

const authCodeSchema = Yup.object().shape({
    authCode: Yup.string()
        .required('Auth code is required')
        .min(6, 'Auth code must be 6 characters')
        .max(6, 'Auth code must be 6 characters')
});

function SignUp() {
    const [randUsernames, setRandUsernames] = useState([])
    const [error, setError] = useState('')
    const [stage, setStage] = useState(0)
    const usernameChoiceCount = 3

    useEffect(() => {
        setRandUsernames(generateRandomUsernames())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { register: authRegister, handleSubmit: authHandleSubmit, formState: { errors: authErrors } } = useForm({
        resolver: yupResolver(authCodeSchema)
    });

    const selectRandomUsername = selectedUserName => {
        setValue('username', selectedUserName)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const generateRandomUsernames = () => {
        const randomUsernames = []

        for (var i = 0; i < usernameChoiceCount; i++) {
            const adj = ADJECTIVES[Math.floor(Math.random() * ADJECTIVES.length)];
            const noun = NOUNS[Math.floor(Math.random() * NOUNS.length)];
            const number = Math.floor(Math.random() * 1000);

            randomUsernames.push(`${adj}${capitalizeFirstLetter(noun)}${number}`)
        }

        return randomUsernames
    }

    const signUp = async ({ username, password, email }) => {
        try {
            await Auth.signUp({
                username: username,
                password,
                attributes: { email },
            })
            setStage(1)
            setError('')
        } catch (err) {
            setError(err)
        }
    }

    const confirmSignUp = async ({ authCode }) => {
        const username = watch("username")

        try {
            await Auth.confirmSignUp(username, authCode)
            alert('Successfully signed up!')
            navigate('/app/login')
        } catch (err) {
            setError(err)
        }
    }

    return (
        <div className="min-h-screen flex flex-col py-10 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    src={logo}
                    alt="Fun Fact Checker"
                />
                <h2 className="mt-1 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Sign Up
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    Or{' '}
                    <Link to="/app/login" className="font-medium text-blue-500 hover:text-blue-500">log in to your account</Link>
                </p>
            </div>

            {stage === 0 && (
                <form onSubmit={handleSubmit(signUp)}>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-gray-100 border- py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-5 text-ffpurple-500"
                                >
                                    Email address
                                </label>
                                <div className="max-w-xl text-xs text-gray-500">
                                    <p>This is only used for logging in</p>
                                </div>
                                <div className="mt-1 rounded-md shadow-sm">
                                    <input
                                        id="email"
                                        placeholder="Email address"
                                        {...register("email")}
                                        className={`${errors.email ? "border-2 border-red-500" : "border border-gray-300 focus:ring-blue focus:border-blue-300"}  appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                                    />
                                </div>
                                {errors.email &&
                                    <div className="max-w-xl text-xs font-medium text-red-500">
                                        <p>{errors.email?.message}</p>
                                    </div>
                                }
                            </div>
                            <div className="mt-6">
                                <div className="block text-sm font-medium leading-5 text-ffpurple-500">
                                    <p>Go random</p>
                                </div>
                                <div className="max-w-xl text-xs text-gray-500">
                                    <p>Can't think of a username? Click on one of these!</p>
                                </div>
                                {randUsernames.map((randUserName) => (
                                    <button
                                        type="button"
                                        key={randUserName}
                                        className="inline-flex items-center px-4 py-2 ml-1 mr-1 mt-1 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ffpurple-500"
                                        onClick={() => selectRandomUsername(randUserName)}
                                    >
                                        {randUserName}
                                    </button>
                                ))}
                            </div>
                            <div className="mt-6">
                                <label
                                    htmlFor="username"
                                    className="block text-sm font-medium leading-5 text-ffpurple-500"
                                >
                                    Username
                                </label>
                                <div className="max-w-xl text-xs text-gray-500">
                                    <p>This is what will be displayed on your facts!</p>
                                </div>
                                <div className="mt-1 rounded-md shadow-sm">
                                    <input
                                        id="username"
                                        placeholder="Username"
                                        {...register("username")}
                                        className={`${errors.username ? "border-2 border-red-500" : "border border-gray-300 focus:ring-blue focus:border-blue-300"}  appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                                    />
                                </div>
                                {errors.username &&
                                    <div className="max-w-xl text-xs font-medium text-red-500">
                                        <p>{errors.username?.message}</p>
                                    </div>
                                }
                            </div>
                            <div className="mt-6">
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium leading-5 text-ffpurple-500"
                                >
                                    Password
                                </label>
                                <div className="max-w-xl text-xs text-gray-500">
                                    <p>8+ characters</p>
                                </div>
                                <div className="mt-1 rounded-md shadow-sm">
                                    <input
                                        id="password"
                                        type="password"
                                        placeholder="Password"
                                        {...register("password")}
                                        className={`${errors.password ? "border-2 border-red-500" : "border border-gray-300 focus:ring-blue focus:border-blue-300"}  appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                                    />
                                </div>
                                {errors.password &&
                                    <div className="max-w-xl text-xs font-medium text-red-500">
                                        <p>{errors.password?.message}</p>
                                    </div>
                                }
                            </div>
                            <div className="mt-6">
                                <label
                                    htmlFor="confirmPassword"
                                    className="block text-sm font-medium leading-5 text-ffpurple-500"
                                >
                                    Confirm Password
                                </label>
                                <div className="mt-1 rounded-md shadow-sm">
                                    <input
                                        id="confirmPassword"
                                        type="password"
                                        placeholder="Confirm Password"
                                        {...register("confirmPassword")}
                                        className={`${errors.confirmPassword ? "border-2 border-red-500" : "border border-gray-300 focus:ring-blue focus:border-blue-300"}  appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                                    />
                                </div>
                                {errors.confirmPassword &&
                                    <div className="max-w-xl text-xs font-medium text-red-500">
                                        <p>{errors.confirmPassword?.message}</p>
                                    </div>
                                }
                            </div>
                            <div className="mt-6">
                                <span className="block w-full rounded-md shadow-sm">
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-ffpurple-500 hover:bg-ffpurple-400 focus:outline-none focus:border-ffpurple-500 focus:ring-ffpurple active:bg-ffpurple-600 transition duration-150 ease-in-out"
                                    >
                                        Sign Up
                                    </button>
                                </span>
                            </div>
                            {error &&
                                <div className="max-w-xl text-xs font-medium text-red-500">
                                    <p>Whoa! {error.message ? error.message : 'Something went wrong!'}. Please try again later.</p>
                                </div>
                            }
                        </div>
                    </div>
                </form>
            )}
            {stage === 1 && (
                <form onSubmit={authHandleSubmit(confirmSignUp)}>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-gray-100 border- py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <div>
                                <label
                                    htmlFor="authCode"
                                    className="block text-sm font-medium leading-5 text-gray-700"
                                >
                                    Auth Code
                                </label>
                                <div className="mt-1 rounded-md shadow-sm">
                                    <input
                                        id="authCode"
                                        placeholder="Authorization Code"
                                        {...authRegister("authCode")}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    />
                                </div>
                                {authErrors.authCode &&
                                    <div className="max-w-xl text-xs font-medium text-red-500">
                                        <p>{authErrors.authCode?.message}</p>
                                    </div>
                                }
                            </div>
                            <div className="mt-6">
                                <span className="block w-full rounded-md shadow-sm">
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-ffpurple-500 hover:bg-ffpurple-400 focus:outline-none focus:border-ffpurple-600 focus:ring-ffpurple active:bg-ffpurple-700 transition duration-150 ease-in-out"
                                    >
                                        Confirm Sign Up
                                    </button>
                                </span>
                            </div>
                            {error &&
                                <div className="max-w-xl text-xs font-medium text-red-500">
                                    <p>Whoa! {error.message ? error.message : 'Something went wrong!'}. Please try again later.</p>
                                </div>
                            }
                        </div>
                    </div>
                </form>
            )}

        </div>
    )
}

export default SignUp
