import React from 'react'

const CancelSvg = () => {
    return (
        <svg
            className="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
        >
            <path
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M1 1l6 6m0-6L1 7"
            />
        </svg>
    )
}

export default CancelSvg
