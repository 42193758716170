import React from 'react'
import ListBulletSvg from '../icons/ListBulletSvg'
import ViewCarouselSvg from '../icons/ViewCarouselSvg'
import TagList from '../shared/TagList'

const ModeratorStats = ({ totalToDo }) => (
    <div>
        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
            <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center">
                        <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3 mb-auto">
                            <ListBulletSvg />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                            <dl>
                                <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                                    Total Todo
                                </dt>
                                <dd className="flex items-baseline">
                                    <div className="text-2xl leading-8 font-semibold text-gray-900">
                                        {totalToDo}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center">
                        <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3 mb-auto">
                            <ViewCarouselSvg />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                            <dl>
                                <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                                    Your Categories
                                </dt>
                                <dd className="flex items-center space-x-6">
                                    <div className="leading-8 ">
                                        <TagList
                                            tags={[
                                                'tag',
                                                'tag2',
                                                'tag3',
                                                'tag3',
                                                'tag3',
                                                'tag3',
                                            ]}
                                        />
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default ModeratorStats
